import bgImage from "assets/images/bg_header_hotstock.png";
import RangeSelect from "components/RangeSelect";
import DataInfoLine from "components/ToolTip/DataInfoLine";
import TradeToggle from "components/TradeToggle";
import { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import {  useNotice } from "utils/store";

import styled from "styled-components";
import TopTrader from "./TopTrader";
import TraderLeague from "./TraderLeague";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isSafari,
  isChrome,
} from "react-device-detect";
import NoticeModal from "components/Modals/NoticeModal";

const Title = styled.div`
  color: #000000;

  font-family: Pretendard;

  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  margin-bottom: 4px;
`;
const Description = styled.div`
  color: #000;
  opacity: 0.6;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
  text-align: left;
  margin-bottom: 16px;
`;
const TitleSection = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 44px 0 58px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tooltip {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 90%;
    width: 210px;
  }

  .example {
    width: 210px;

    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.51px;
    text-align: left;
    color: #ffffff;

    p {
      font-weight: 400;
      color: #c8c9cc;
      margin-bottom: 0;
    }
  }
`;

const SectionWrapper = styled.div`
  width: 1240px;
`;

const ToggleSectionWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  margin-top: 16px;
`;

const ToggleWrapper = styled.div`
  width: 210px;
`;

const TextWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
`;

const TraderList = () => {
  const navigate = useNavigate();

  const [type, setType] = useState("league");

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (isError) {
  //   return <p>Error loading data</p>;
  // }


  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);


  useEffect(()=>{
    if(isMobile && isNoticeShow===false) {
      setNoticeOpen(true);
    }
  },[])


  return (
    <HomeWrapper>
    <NoticeModal
            isOpen={isNoticeOpen}
            onCancel={()=>{setNoticeOpen(false); }}
          />
          
      <TitleSection>
        <TextWrapper>
          <Title>투자대회 100위권 최상위 트레이더를 찾아보세요</Title>
          <Description>
            최상위 트레이더들이 어떻게 투자하는지 한눈에 배워보세요.
          </Description>
          <RangeSelect />
        </TextWrapper>
      </TitleSection>

      <ToggleSectionWrapper>
        <ToggleWrapper>
          <TradeToggle
            setToggleState={(type) => {
              setType(type);
            }}
          />
        </ToggleWrapper>
        <DataInfoLine />
      </ToggleSectionWrapper>
      {type === "trader" ? (
        <SectionWrapper>
          <TopTrader />
          <Tooltip id={"trader-tooltip"} place={"bottom"} className="example" />
        </SectionWrapper>
      ) : (
        <ToggleSectionWrapper>
          <TraderLeague />
        </ToggleSectionWrapper>
      )}
    </HomeWrapper>
  );
};

export default TraderList;
