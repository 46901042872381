import { CartesianGrid, Line, LineChart, YAxis } from "recharts";
import styled from "styled-components";
import { TRADER_TOOLTIP_TEXT } from "utils/consts";
import {
  convertToPercentage,
  convertToPlusWon,
  unescapeHtml,
} from "utils/utils";
import TaggedUserName from "./TaggedUserName";

const IndexSpan = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  line-height: 19.5px;
  text-align: center;
  margin-right: 12px;
  color: ${(props) => (props.active === "true" ? "#000000" : "#969799")};
`;

const CardWrapper = styled.div`
  min-width: ${(props) => props.width - 2 || 360 - 2}px;
  width: ${(props) => props.width - 2 || 360 - 2}px;
  border-radius: 8px;
  gap: 0px;
  outline: 1px solid
    ${(props) => (props.active === "true" ? "#000000" : "#ebebeb")};
  outline-offset: -1px;
  ${(props) =>
    props.active === "true" && "box-shadow: 2px 4px 18px 0px #00000014;"};
  background: ${(props) => (props.active === "true" ? "#fff" : "#FCFCFC")};
  padding: 16px;

  &:hover {
    cursor: pointer;
    background: #fff;
    outline: 1px solid
      ${(props) => (props.active === "true" ? "#000000" : "#ebebeb")};
    outline-offset: -1px;
  }

  .username {
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.9px;
    text-align: left;
  }
`;
const ProfitSection = styled.div`
  margin-top: 14px;
  margin-bottom: 15px;
  width: inherit;
  display: flex;
  justify-content: space-between;
`;
const InfoSection = styled.div`
  padding: 12px;
  gap: 10px;
  border-radius: 6px;

  opacity: 0px;
  background: #fafafa;
`;
const NameSection = styled.div`
  display: flex;
`;

const ProfitValueWrapper = styled.div``;
const ProfitPercent = styled.div`
  font-family: Pretendard;
  font-size: 26px;
  font-weight: 600;
  line-height: 31.03px;
  text-align: left;
  color: #ff2727;
`;
const ProfitWon = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;
  color: #141414;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const InfoName = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;

  color: #969799;
`;

const InfoData = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.51px;

  color: #323233;
`;

const TraderCard = ({
  data,
  index,
  infoTitle = ["거래 날짜", "거래 종목", "종목 승률", "거래 횟수"],
  infoData = ["", "", "", ""],
  width = 328,
  active = true,
  onClick = () => {},
  graphWidth,
  graphHeight,
  actionStyle,
  // noYAxis = false,
  //   tag = "",
  //   isScalping = false,
  //   isDarkMode = false,
}) => {
  return (
    <CardWrapper
      width={width}
      active={active.toString()}
      onClick={onClick}
      style={actionStyle}
    >
      <NameSection>
        <IndexSpan active={active.toString()}>{index}</IndexSpan>
        <TaggedUserName
          active={active.toString()}
          name={data?.nick}
          tag={data?.league}
          isDarkMode={false}
          fontWeight={600}
        />
      </NameSection>
      <ProfitSection>
        <ProfitValueWrapper>
          <ProfitPercent>{convertToPercentage(data?.ret)}</ProfitPercent>
          <ProfitWon>{convertToPlusWon(data?.pnl)}</ProfitWon>
        </ProfitValueWrapper>

        <LineChart
          width={graphWidth || (width >= 360 ? 190 : 120)}
          height={graphHeight || (width >= 360 ? 48 : 30)}
          data={data?.chart_ret?.map((value) => ({ value }))}
        >
          {width >= 360 && (
            <>
              <YAxis domain={["dataMin", "dataMax"]} allowDecimals={true} />
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
            </>
          )}
          <Line dot={false} dataKey="value" stroke={"#FF2727"} />
        </LineChart>
      </ProfitSection>
      <InfoSection>
        {infoTitle.map((title, i) => (
          <InfoWrapper key={i}>
            <InfoName
              data-tooltip-id="trader-tooltip"
              data-tooltip-html={`<div className="title">${
                TRADER_TOOLTIP_TEXT[title]?.title || ""
              }<div/><p>${TRADER_TOOLTIP_TEXT[title]?.body || ""}</p>`}
            >
              {title}
            </InfoName>
            <InfoData>{unescapeHtml(infoData[i])}</InfoData>
          </InfoWrapper>
        ))}
      </InfoSection>
    </CardWrapper>
  );
};

export default TraderCard;
