import NoticeModal from "components/Modals/NoticeModal";
import { useAlphaTraderDetailQuery } from "hooks/queries/useAlphaTraderDetailQuery";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNotice, useSelectedCompetition } from "utils/store";
import AlphaDetailHome from "./AlphaDetailHome";
import AlphaDetailInfo from "./AlphaDetailInfo";
import AlphaDetailNote from "./AlphaDetailNote";
import TitleSection from "./TitleSection";

const HomeWrapper = styled.div`
  width: 100%;
  padding-bottom: 127px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f9fafc;

  .section-bg {
    width: 100%;
    background: #121314;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 1128px;
  }

  .datainfo {
    padding: 12px 0;
  }
`;

const TraderDetail = () => {
  const { hash } = window.location;
  console.log("hash", hash);

  const { cuid } = useParams();

  const [type, setType] = useState(
    hash.replace(/^#/, "").split("?")[0] || "summary"
  );
  const [isHold, setIsHold] = useState(false);

  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const [lastUpdate, setLastUpdate] = useState("");
  // const { data: summaryPerformanceData } = useAnalysisSummaryPerformanceQuery({
  //   cid: selectedCompetition?.cid,
  //   cuid: cuid,
  // });

  useEffect(() => {
    setIsHold(hash?.split("?")[1] === "hold=true");

    setType(hash.replace(/^#/, "").split("?")[0] || "summary");
  }, [hash]);

  const { data: summaryPerformanceData, isLoading } = useAlphaTraderDetailQuery(
    {
      cuid: cuid,
    }
  );

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    window.location.hash = type;
  }, [type]);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (isError) {
  //   return <p>Error loading data</p>;
  // }

  return (
    <HomeWrapper>
      <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      />

      <div className="section-bg">
        <div className="fullWidth">
          {!isLoading && summaryPerformanceData && (
            <TitleSection
              data={summaryPerformanceData || {}}
              setTab={setType}
              selectedTab={type}
            />
          )}
        </div>
      </div>
      <div className="fullWidth">
        {type === "summary" && (
          <AlphaDetailHome cuid={cuid} setLastUpdate={setLastUpdate} />
        )}
        {type === "date" && (
          <AlphaDetailInfo cuid={cuid} lastUpdate={lastUpdate} />
        )}
        {type === "stock" && (
          <AlphaDetailNote
            cuid={cuid}
            lastUpdate={lastUpdate}
            isHold={isHold}
          />
        )}
      </div>
      {/* <Tooltip id={"detail-tooltip"} place={"bottom"} /> */}
    </HomeWrapper>
  );
};

export default TraderDetail;
