import axios from "axios";

// import { API_URL } from "../../shared/config";

// const api_url = "http://121.134.241.229:9000/api/v1/";
// const api_url = "https://api.alphanote.io/api/v1/";
const api_url = "https://api.alphanote.io/api/v1/";

export const api_v_url = "https://dev-api.alphanote.io/api/";

export default function useHttpClient(baseURL = api_url) {
  //   const state = useAuthStore((state) => state);
  //   if (!state.authProviderUsed) {
  //     throw new Error("useHttpClient는 AuthProvider와 함께 사용되어야 합니다.");
  //   }
  const httpClient = axios.create({ baseURL });
  //   httpClient.interceptors.request.use((config) => {
  //     config.headers.Authorization = `Bearer ${state.token}`;
  //     return config;
  //   });
  //   httpClient.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //       if (error.response.status === 401) {
  //         const result = await refreshToken();
  //         if (result && state.token !== result) {
  //           state.setToken(result);
  //         }
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  return httpClient;
}
