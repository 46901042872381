import { ReactComponent as ArrowLeftIcon } from "assets/images/ico_arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/ico_arrow_right.svg";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { INFO_TITLE } from "utils/consts";
import { convertToPercentage } from "utils/utils";
import TraderCard from "./TraderCard";

const CardSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const LeftRoundButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e7e9ef;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 14px 0px #767d9217;
  margin-right: 16px;

  &:hover {
    background-color: #f7f8fa;
  }

  &:active {
    background-color: #e7e9ef;
    border: 1px solid #dcdee3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const RightRoundButton = styled.button`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e7e9ef;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 14px 0px #767d9217;
  margin-left: 16px;

  &:hover {
    background-color: #f7f8fa;
  }

  &:active {
    background-color: #e7e9ef;
    border: 1px solid #dcdee3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LeftButton = ({ onClick, disabled }) => {
  return (
    <LeftRoundButton onClick={onClick} disabled={disabled}>
      <ArrowLeftIcon />
    </LeftRoundButton>
  );
};

const RightButton = ({ onClick, disabled }) => {
  return (
    <RightRoundButton onClick={onClick} disabled={disabled}>
      <ArrowRightIcon />
    </RightRoundButton>
  );
};

const ScrollCardSection = ({ data, type = "scalping", width = 328 }) => {
  const cardSectionRef = useRef();

  const handleCardClick = (id) => {
    window.location.href = `/trader/${id}`;
  };

  const handleButtonClick = (type) => {
    const widthWithGap = width + 24 + 32;
    const scrollWay = type === "left" ? 1 : -1;
    const currentIndex = Math.floor(
      cardSectionRef?.current?.scrollLeft / widthWithGap
    );

    cardSectionRef.current.scrollTo({
      left: (currentIndex + scrollWay) * widthWithGap,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    cardSectionRef.current.scrollTo({
      left: 0,
      behavior: "instant",
    });
  }, [data]);

  return (
    <CardSectionWrapper>
      <LeftButton onClick={() => handleButtonClick("right")} disabled={false} />
      <CardSection ref={cardSectionRef}>
        {data &&
          data.map((item, index) => (
            <TraderCard
              width={width}
              infoTitle={INFO_TITLE[type]}
              infoData={[
                convertToPercentage(item?.win_rate),
                convertToPercentage(item?.avg_ret),
                item?.avg_hold_time.toFixed(0),
                item?.avg_n_tr.toFixed(2),
              ]}
              data={item}
              index={index + 1}
              onClick={() => handleCardClick(item.cuid)}
              graphWidth={100}
              graphHeight={48}
            />
          ))}
      </CardSection>
      <RightButton onClick={() => handleButtonClick("left")} disabled={false} />
    </CardSectionWrapper>
  );
};

export default ScrollCardSection;
