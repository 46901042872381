import ToggleComponet from "components/ToggleComponet";
import { css } from "styled-components";

import { useTraderDateProformanceQuery } from "hooks/queries/useTraderDetailQuery";
import { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import MyApexChart from "components/MyApexChart";
import StyledDatePicker from "components/StyledDatePicker";
import styled from "styled-components";
import { useSelectedCompetition } from "utils/store";
import {
  convertToNearWon,
  convertToPercentage,
  convertToWon,
  formatDateToYMD,
  formatSec,
  unescapeHtml,
} from "utils/utils";

import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";

const Section = styled.div``;

const CardWrapper = styled.div`
  height: 500px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  width: 100%;
  margin: 32px 0;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: column;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
  }

  .toggle {
    width: fit-content;
    padding: 0 24px;
  }

  .graph {
    height: 360px;
  }
`;

const ChartCardWrapper = styled.div`
  min-height: 532px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  width: 100%;
  margin: 32px 0;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: column;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
    display: flex;

    .react-datepicker-wrapper {
      padding: 0;
    }
  }

  .toggle {
    width: fit-content;
    padding: 0 24px;
  }

  .graph {
    height: 360px;
  }

  .description {
    padding: 0 24px 28px;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: left;
    color: #7d7e80;
    display: flex;
    gap: 16px;

    span {
      color: #141414;
      margin-left: 4px;
    }
  }
`;

const HistoryCardWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebedf5;
  width: 100%;
  margin: 32px 0;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;

  display: flex;
  flex-direction: column;

  .title {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    padding: 24px 24px 16px;
  }

  .toggle {
    width: fit-content;
    padding: 0 24px;
  }

  .graph {
    height: 360px;
  }
`;

const convertData = (chartData) => {
  return (
    chartData?.date?.map((date, index) => ({
      date: date,
      pnl: chartData.pnl[index],
      cum_pnl: chartData.cum_pnl[index],
      ret: chartData.ret[index] * 100,
      cum_ret: chartData.cum_ret[index] * 100,
    })) || []
  );
};

const GraphCard = ({ graphData = {}, isLoading = true }) => {
  const [currentType, setCurrentType] = useState("ret");

  const data = convertData(graphData);

  return (
    <CardWrapper>
      <div className="title">날짜별 성과</div>
      <div className="toggle">
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "수익률", type: "ret" },
            { name: "수익금", type: "pnl" },
          ]}
        />
      </div>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <div className="graph">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="date"
                tickFormatter={(tickItem) => {
                  const date = new Date(tickItem * 1000);
                  const month = (date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0"); // months are 0-based in JavaScript
                  const day = date.getDate().toString().padStart(2, "0");
                  return `${month}-${day}`;
                }}
              />
              <YAxis />

              <Tooltip cursor={{ fill: "transparent" }} />
              <Legend verticalAlign="top" align="left" height={33} />
              <Bar dataKey={currentType} barSize={20} fill="#FFD5E0" />
              <Line
                type="monotone"
                dataKey={currentType === "ret" ? "cum_ret" : "cum_pnl"}
                stroke="#FF3D6C"
                dot={false}
                activeDot={false}
              />
              <ReferenceLine y={0} stroke="#000" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </CardWrapper>
  );
};

const HistoryCellStyle = css`
  text-align: right;

  &:nth-child(1) {
    text-align: left;
    width: calc(100px - 24px);
  }

  &:nth-child(2) {
    width: calc(70px - 24px);
  }

  &:nth-child(3) {
    width: calc(90px - 24px);
  }

  &:nth-child(4) {
    width: calc(100px - 24px);
  }

  &:nth-child(5) {
    width: calc(140px - 24px);
  }

  &:nth-child(6) {
    width: calc(140px - 24px);
  }

  &:nth-child(7) {
    width: calc(90px - 24px);
  }

  &:nth-child(8) {
    width: calc(110px - 24px);
  }
  &:nth-child(9) {
    width: calc(100px - 24px);
  }
  &:nth-child(10) {
    width: calc(140px - 24px);
  }
`;

const TableWrapper = styled.div`
  padding: 0 20px 20px;

  thead,
  tbody tr {
    display: table;
    width: 100%;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableHeaderCell = styled.th`
  ${HistoryCellStyle}
  height: 36px;
  padding: 0 12px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.32px;

  border-bottom: 1px solid #ebedf5;
`;

const StyledTableCell = styled.td`
  ${HistoryCellStyle}
  height: 36px;
  padding: 0 12px;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;

  border-bottom: 1px solid #f2f4f7;

  color: ${(props) =>
    props?.value ? (props.value > 0 ? "#FF2727" : "#4C67FF") : "#323233"};
`;

const TableBody = styled.tbody`
  display: block;
  max-height: 505px;
  overflow-y: auto;
`;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;
const convertDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${parseInt(year) + 1}.${month}.${day}`;
};

const ScrollTable = ({ data }) => {
  const [sortedData, setSortedData] = useState(data);

  const handleSort = (key) => {
    const sorted = [...data].sort((a, b) => {
      if (key === "date") {
        return new Date(a.date) - new Date(b.date);
      } else if (key === "ret") {
        return parseFloat(a.ret) - parseFloat(b.ret);
      }
      return 0;
    });
    setSortedData(sorted);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <StyledTableHeaderCell onClick={() => handleSort("date")}>
            날짜
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>거래</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래승률</StyledTableHeaderCell>
          <StyledTableHeaderCell>수익률</StyledTableHeaderCell>
          <StyledTableHeaderCell>투자손익</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래 대금</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균 체결</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균 보유시간</StyledTableHeaderCell>
          <StyledTableHeaderCell>누적 수익률</StyledTableHeaderCell>
          <StyledTableHeaderCell>누적 수익금</StyledTableHeaderCell>
        </thead>
        <TableBody>
          {/* <tbody> */}
          {sortedData?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{convertDate(row.date)}</StyledTableCell>
              <StyledTableCell>{row.n_tr + "번"}</StyledTableCell>
              <StyledTableCell>
                {convertToPercentage(row.win_rate)}
              </StyledTableCell>
              <StyledTableCell value={row.ret}>
                {convertToPercentage(row.ret)}
              </StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.pnl)}</StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.amt)}</StyledTableCell>
              <StyledTableCell>{row.avg_trxansaction}</StyledTableCell>
              <StyledTableCell>{formatSec(row.avg_hold_time)}</StyledTableCell>
              <StyledTableCell value={row.cum_ret}>
                {convertToPercentage(row.cum_ret)}
              </StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.cum_pnl)}</StyledTableCell>
            </StyledTableRow>
          ))}
          {/* </tbody> */}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
};

const HistoryTableCard = ({
  historyData = {},
  isLoading = true,
  title = "날짜별 성과 History",
}) => {
  return (
    <HistoryCardWrapper>
      <div className="title">{title}</div>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <div className="table">
          {/* <ScrollTableBody data={historyData} /> */}
          <ScrollTable data={historyData} />
        </div>
      )}
    </HistoryCardWrapper>
  );
};

const StockTable = ({ data = [] }) => {
  const [sortedData, setSortedData] = useState(data || []);

  useEffect(() => {
    setSortedData(data || []);
  }, [data]);

  const handleSort = (key) => {
    // const sorted = [...data].sort((a, b) => {
    //   if (key === "date") {
    //     return new Date(a.date) - new Date(b.date);
    //   } else if (key === "ret") {
    //     return parseFloat(a.ret) - parseFloat(b.ret);
    //   }
    //   return 0;
    // });
    // setSortedData(sorted);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <StyledTableHeaderCell onClick={() => handleSort("date")}>
            종목
          </StyledTableHeaderCell>
          <StyledTableHeaderCell>수익률</StyledTableHeaderCell>
          <StyledTableHeaderCell>수익금</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래 대금</StyledTableHeaderCell>
          <StyledTableHeaderCell>거래</StyledTableHeaderCell>
          <StyledTableHeaderCell>체결</StyledTableHeaderCell>
          <StyledTableHeaderCell>평균보유시간</StyledTableHeaderCell>
        </thead>
        <TableBody>
          {sortedData?.map((row, index) => (
            <StyledTableRow
              key={index}
              onClick={() => {
                window.open(
                  `/note?cuid=${row.note_id.cuid}&code=${row.note_id.code}&date=${row.note_id.date}`,
                  "newwindow",
                  "width=1300, height=1200"
                );
              }}
            >
              <StyledTableCell>{unescapeHtml(row.name)}</StyledTableCell>
              <StyledTableCell value={row.ret}>
                {convertToPercentage(row.ret)}
              </StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.pnl)}</StyledTableCell>
              <StyledTableCell>{convertToNearWon(row.amt)}</StyledTableCell>
              <StyledTableCell>{row.n_tr + "건"}</StyledTableCell>
              <StyledTableCell>{row.n_trx + "건"}</StyledTableCell>
              <StyledTableCell>{formatSec(row.avg_hold_time)}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
};

const CalendarButtonWrapper = styled.div`
  align-items: center;
  margin-left: 16px;
  border-collapse: collapse;
  display: flex;

  .left {
    border-radius: 4px 0 0 4px;
  }

  .right {
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
`;

const CalendarButton = styled.div`
  width: 26px;
  height: 24px;
  background: #ffffff;

  text-align: center;
  cursor: pointer;
  border: 1px solid #e7e9ef;

  &:hover {
    background: #f2f4f7;
  }

  &:active {
    background: #e7e9ef;
  }
`;

const StockCard = ({ stockData = {}, isLoading = true }) => {
  const [validDates, setValidDates] = useState(
    Object.keys(stockData || {}).sort((a, b) => b.localeCompare(a))
  );
  const [currentDate, setCurrentDate] = useState(
    Object.keys(stockData || {}).sort((a, b) => b.localeCompare(a))[0]
  );
  const [currentType, setCurrentType] = useState("chart");
  const [currentData, setCurrentData] = useState(stockData[currentDate]);

  const { info } = currentData || {};
  useEffect(() => {
    const newValidDates = Object.keys(stockData || {}).sort((a, b) =>
      b.localeCompare(a)
    );
    setValidDates(newValidDates);
    setCurrentDate(newValidDates[0]);
  }, [isLoading]);

  useEffect(() => {
    setCurrentData(stockData[formatDateToYMD(new Date(currentDate))]);
  }, [currentDate]);

  const handlePrevDate = () => {
    const index = validDates.findIndex(
      (date) => new Date(date).getTime() === new Date(currentDate).getTime()
    );
    if (index < validDates.length - 1) {
      setCurrentDate(validDates[index + 1]);
    }
  };

  const handleNextDate = () => {
    const index = validDates.findIndex(
      (date) => new Date(date).getTime() === new Date(currentDate).getTime()
    );
    if (index > 0) {
      setCurrentDate(validDates[index - 1]);
    }
  };

  return (
    <ChartCardWrapper>
      {isLoading ? (
        <div>로딩중</div>
      ) : (
        <>
          <div className="title">
            <StyledDatePicker
              currentDate={currentDate}
              onDateSelect={setCurrentDate}
              includeDates={validDates}
            />

            <CalendarButtonWrapper>
              <CalendarButton className="left" onClick={() => handlePrevDate()}>
                <ArrowLeft />
              </CalendarButton>
              <CalendarButton
                className="right"
                onClick={() => handleNextDate()}
              >
                <ArrowRight />
              </CalendarButton>
            </CalendarButtonWrapper>
          </div>

          <div className="description">
            <div>
              종목수<span>{info?.n_stock}종목</span>
            </div>
            <div>
              수익금<span>{convertToWon(info?.pnl)}</span>
            </div>
            <div>
              수익률<span>{convertToPercentage(info?.ret)}</span>
            </div>
          </div>
          <div className="toggle">
            <ToggleComponet
              onClick={(type) => setCurrentType(type)}
              labels={[
                { name: "종목 변화", type: "chart" },
                { name: "전체 종목", type: "stocks" },
              ]}
            />
          </div>
          {currentType === "chart" ? (
            <ChartWrapper>
              {currentData && (
                <MyApexChart
                  data={currentData.chart}
                  height={340}
                  noteIdList={currentData.stocks.map((item) => item.note_id)}
                />
              )}
            </ChartWrapper>
          ) : (
            <StockTable data={currentData?.stocks} />
          )}
        </>
      )}
    </ChartCardWrapper>
  );
};

const ChartWrapper = styled.div`
  padding: 16px;
`;

const DetailDateSection = ({ cuid = "" }) => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const {
    data: graphData,
    isLoading: isDataLoading,
    isError: isDataError,
  } = useTraderDateProformanceQuery({ cid: selectedCompetition?.cid, cuid });

  return (
    <Section>
      <StockCard
        title="날짜"
        stockData={graphData?.stock_change}
        isLoading={isDataLoading}
      />
      <GraphCard
        title="날짜별 성과"
        graphData={graphData?.performance}
        isLoading={isDataLoading}
      />
      <HistoryTableCard
        title="날짜별 성과 History"
        historyData={graphData?.hist_day}
        isLoading={isDataLoading}
      />
    </Section>
  );
};

export default DetailDateSection;
