import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import ReactGA from "react-ga4";
import ReactModal from "react-modal";
import ScrollToTop from "utils/ScrollToTop";

// Initialize ReactGA only if the current URL is not "dev.alphanote.io"
if (window.location.hostname === "alphanote.io") {
  ReactGA.initialize("G-SFVVXEP5Z8");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

ReactModal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
