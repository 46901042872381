import { positionsLine } from "./positions";

class VertLinePaneRenderer {
  _x = null;
  _options;
  _barSpacing = null;
  constructor(x, options, barSpacing) {
    this._x = x;
    this._options = options;
    this._barSpacing = barSpacing;
  }
  draw(target) {
    target.useBitmapCoordinateSpace((scope) => {
      if (this._x === null) return;
      const ctx = scope.context;
      const position = positionsLine(
        // this._x - (this._barSpacing / 2) * 0.8,
        this._x - this._barSpacing / 2,
        scope.horizontalPixelRatio,
        this._options.width
      );
      ctx.fillStyle = this._options.color;
      ctx.fillRect(
        position.position,
        0,
        position.length,
        scope.bitmapSize.height
      );
    });
  }
}

class VertLinePaneView {
  _source;
  _x = null;
  _barSpacing = null;
  _options;

  constructor(source, options) {
    this._source = source;
    this._options = options;
  }
  update() {
    const timeScale = this._source._chart.timeScale();
    this._x = timeScale.timeToCoordinate(this._source._time);
    this._barSpacing = timeScale._private__timeScale._private__barSpacing;
  }
  renderer() {
    return new VertLinePaneRenderer(this._x, this._options, this._barSpacing);
  }
}

class VertLineTimeAxisView {
  _source;
  _x = null;
  _options;

  constructor(source, options) {
    this._source = source;
    this._options = options;
  }
  update() {
    const timeScale = this._source._chart.timeScale();
    this._x = timeScale.timeToCoordinate(this._source._time);
  }
  visible() {
    return this._options.showLabel;
  }
  tickVisible() {
    return this._options.showLabel;
  }
  coordinate() {
    return this._x ?? 0;
  }
  text() {
    return this._options.labelText;
  }
  textColor() {
    return this._options.labelTextColor;
  }
  backColor() {
    return this._options.labelBackgroundColor;
  }
}

const defaultOptions = {
  color: "green",
  labelText: "",
  width: 1,
  labelBackgroundColor: "green",
  labelTextColor: "white",
  showLabel: false,
};

class VertLine {
  _chart;
  _series;
  _time;
  _paneViews;
  _timeAxisViews;

  constructor(chart, series, time, options) {
    const vertLineOptions = {
      ...defaultOptions,
      ...options,
    };
    this._chart = chart;
    this._series = series;
    this._time = time;
    this._paneViews = [new VertLinePaneView(this, vertLineOptions)];
    this._timeAxisViews = [new VertLineTimeAxisView(this, vertLineOptions)];
  }
  updateAllViews() {
    this._paneViews.forEach((pw) => pw.update());
    this._timeAxisViews.forEach((tw) => tw.update());
  }
  timeAxisViews() {
    return this._timeAxisViews;
  }
  paneViews() {
    return this._paneViews;
  }
}

export { VertLine };
