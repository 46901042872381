import styled from "styled-components";
import AlphaTag from "./AlphaTag";

const Name = styled.span`
  color: ${(props) =>
    props.active === "true"
      ? props.is_dark === "dark"
        ? "#C8C9CC"
        : "#121314"
      : "#969799"};

  font-family: Pretendard;
  font-size: 15px;
  font-weight: ${(props) => props.fontWeight};
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// Table component
const TaggedUserName = ({
  name = "",
  tag = "",
  isScalping = false,
  isDarkMode = false,
  fontWeight = 400,
  active = true,
}) => {
  return (
    <TagWrapper>
      <Name
        type={tag}
        is_dark={isDarkMode ? "dark" : "default"}
        fontWeight={fontWeight}
        active={active.toString()}
      >
        {name}
      </Name>
      <AlphaTag is_dark={isDarkMode ? "dark" : "default"} type={tag}>
        {tag}
      </AlphaTag>
      {isScalping && <AlphaTag>Scalping</AlphaTag>}
    </TagWrapper>
  );
};

export default TaggedUserName;
