import { Link } from "react-router-dom";
import styled from "styled-components";
import { convertToPercentage, convertToPlusWon, convertToNearWon } from "utils/utils";
import AlphaTag from "./AlphaTag";
import TaggedUserName from "./TaggedUserName";

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .user {
    margin-right: auto;
  }

  .profit {
    margin-right: 6px;
    text-align-last: right;
  }
`;

const Scrap = styled.div`
  color: #ffffff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  opacity: 0.6px;
`;

const CellWrapper = styled(Link)`
  height: 60px;
  display: flex;
  border-radius: 6px;
  padding: 0 8px;
  text-decoration: none;

  &:hover {
    background-color: rgba(247, 250, 255, 0.05); // Updated line

    color: #ffffff;
  }
`;

const Profit = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #ff5857;
`;

const Description = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  color: #7d7e80;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const SummaryCellRanker = ({
  name = "",
  tag = "",
  profit = "",
  profitWon = "",
  description = "",
  scrapCount = "",
  isScraped = false,
  id = "",
}) => {
  return (
    <CellWrapper to={`/trader/${id}`}>
      <ItemWrapper>
        <AlphaTag
          style={{
            width: 25,
            height: 36,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          type={tag}
          is_dark={"dark"}
        >
          {tag}
        </AlphaTag>
        <Item className="user">
          <TaggedUserName name={name} isDarkMode={true} />
          <Description>{description + "명 중 1위"}</Description>
        </Item>
        <Item className="profit">
          <Profit>{convertToPercentage(profit)}</Profit>
          <Description>
            {profitWon ? convertToNearWon(profitWon) : "추정 수익금 없음"}
          </Description>
        </Item>
        {/* <Scrap isScraped={isScraped}>{scrapCount}</Scrap> */}
      </ItemWrapper>
    </CellWrapper>
  );
};

export default SummaryCellRanker;
