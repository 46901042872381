import { Link } from "react-router-dom";
import styled from "styled-components";
import TaggedUserName from "./TaggedUserName";

const Index = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #e7e9ef;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Scrap = styled.div`
  color: #ffffff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: auto;

  opacity: 0.6px;
`;

const CellWrapper = styled(Link)`
  height: 60px;
  display: flex;
  border-radius: 6px;
  padding: 0 8px;
  text-decoration: none;

  &:hover {
    background-color: rgba(247, 250, 255, 0.05);

    color: #ffffff;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Description = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #7d7e80;

  &:last-child {
    margin-left: 2px;
    color: #ff5857;
  }
`;

const DescriptionBar = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin: 0 3px;
  color: #4b4c4d;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
`;

// Table component
const SummaryCellJournal = ({
  index = 1,
  name = "",
  tag = "",
  profit = "",
  scrapCount = "",
  date = "",
  subject = "",
  isScraped = false,
  note_id = "",
}) => {
  return (
    <CellWrapper
      href={`/note?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}`}
      onClick={(event) => {
        window.open(`/note?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}`, 'newWindow', 'width=1300,height=1200');
      }}
      rel="noopener noreferrer"
    >
      <ItemWrapper>
        <Index>{index}</Index>
        <Item>
          <TaggedUserName name={name} tag={tag} isDarkMode={true} />
          <InfoWrapper>
            <Description>{date}</Description>
            <DescriptionBar>|</DescriptionBar>
            <Description>{subject}</Description>
            <Description color="red">{profit.toFixed(2)}%</Description>
          </InfoWrapper>
        </Item>
        {/* <Scrap isScraped={isScraped}>{scrapCount}</Scrap> */}
      </ItemWrapper>
    </CellWrapper>
  );
};

export default SummaryCellJournal;
