import BookmarkIcon from "assets/images/ico_bookmark.png";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { convertToNearWon, convertToPercentage } from "utils/utils";
import AlphaStyleTag from "./AlphaStyleTag";
import TraderLike from "./Reaction/TraderLike";
import { SvgStkImg } from "./StockIcon";

const CardWrapper = styled.div`
  box-sizing: border-box;
  padding: 16px;
  width: 360px;
  height: 282px;
  box-shadow: 5px 5px 22px 0px #6574900f;
  border: 1px solid #ebedf5;
  border-radius: 8px;
  font-family: Pretendard;

  .top {
    width: 100%;
    display: flex;

    img {
      width: 20px;
      height: 20px;
    }

    .icons {
      margin-left: auto;
      display: flex;
      gap: 5px;
    }

    .idx {
      font-family: Montserrat;
      font-size: 17px;
      font-style: italic;
      font-weight: 700;
      line-height: 20.72px;

      color: #323233;
    }
    .nick {
      margin-left: 7px;
      font-size: 17px;
      font-weight: 600;
      line-height: 20.29px;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tag {
      display: flex;
    }
  }

  .mid {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;

    .right {
      text-align: right;
    }

    .m1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.64px;
    }
    .m2 {
      margin-top: 2px;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
    }
  }

  .bottom {
    background: #fafafa;
    padding: 14px 16px;
    box-sizing: border-box;
    gap: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 6px;

    .stock {
      display: flex;
      align-items: center;

      .name {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.71px;
        text-align: left;

        color: #141414;
        margin-right: auto;
        margin-left: 8px;
      }

      .ret {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.71px;
        text-align: right;
      }
      .hold {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.32px;
        text-align: right;
        color: #4b4c4d;
      }
      .pnl {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.32px;
        text-align: right;
        color: #4b4c4d;
      }

      .red {
        color: ${(props) => (props.selected ? "#FF5857" : "#FF2727")};
      }
      .blue {
        color: ${(props) => (props.selected ? "#5E87FF" : "#4C67FF")};
      }
    }
  }

  &:hover {
    cursor: pointer;
    background: #fff;
    border: 1px solid #000000;
  }
`;
const AlphaTraderCard = ({
  index,
  data,
  type,
  onClick = () => {},
  updateIsLiked = () => {},
}) => {
  const {
    nick,
    n_stock,
    n_tr,
    pnl,
    rank,
    ret,
    sequence,
    stocks,
    style,
    league,
    weight,
    status,
    cuid,
    like,
  } = data;

  return (
    <CardWrapper onClick={onClick}>
      <div className="top">
        <span className="idx">{index}</span>
        <span className="nick">{nick}</span>
        <AlphaStyleTag type={style || league}>
          {TRADE_STYLE_TYPE[style] || league}
        </AlphaStyleTag>
        <div className="icons">
          <img style={{ display: "none" }} src={BookmarkIcon}></img>
          <TraderLike
            payload={{ cuid: cuid }}
            status={like}
            updateIsLiked={updateIsLiked}
          />
        </div>
      </div>
      <div className="mid">
        <div>
          <div className="m1">{n_stock}종목</div>
          <div className="m2">{n_tr}건 매매</div>
        </div>
        <div className="right">
          <div className="m1">{convertToPercentage(ret)}</div>
          <div className="m2">{convertToNearWon(pnl)}</div>
        </div>
      </div>
      <div className="bottom">
        {stocks.map((stock, idx) => (
          <div className="stock" key={idx}>
            <SvgStkImg code={stock?.code} name={stock?.name} size={24} />
            <div className="name">{stock.name}</div>
            <div>
              <div className={`ret ${stock.ret < 0 ? "blue" : "red"}`}>
                {convertToPercentage(stock.ret)}
              </div>
              {stock.status === "holding" ? (
                // <div className="hold">보유 중({convertToPercentage(stock.weight)})</div>
                <div className="hold">보유 중</div>
              ) : (
                <div className="pnl">{convertToNearWon(stock.pnl)}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </CardWrapper>
  );
};

export default AlphaTraderCard;
