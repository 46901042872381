import { useHotStockQuery } from "hooks/queries/useHomeQuery";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useSelectedCompetition } from "utils/store";
import { convertToNearWon, unescapeHtml } from "utils/utils";
import IndexNumber from "./IndexNumber";
import { SvgStkImg } from "components/StockIcon";

const itemContainerStyle = {
  display: 'flex',
  alignItems: 'center'
};

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
`;

const StyledTableRow = styled.tr`
  &:hover {
    background-color: #f7f8fa;
  }
`;

const StyleCellWidth = css`
  padding: 0;

  .item {
    margin-left: 12px;
  }

  &:nth-child(1) {
    width: 90px;
  }
  &:nth-child(2) {
    width: 300px;
  }

  &:nth-child(4) {
    width: 280px;
  }
  &:nth-child(5) {
    width: 120px;
  }
  &:nth-child(6) {
    width: 190px;
    text-align: right;

    .item {
      margin-left: 0;
      margin-right: 12px;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  border: 1px solid #e7e9ef;

  border-radius: 8px;
  table-layout: fixed;
`;

const StyledHeader = styled.th`
  height: 36px;
  text-align: left;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;

  color: #969799;

  border-bottom: 1px solid #e7e9ef;

  ${StyleCellWidth}
`;

const StyledCell = styled.td`
  height: 72px;
  padding: 8px;
  color: #121314;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  ${StyleCellWidth}

  .itemLink {
    margin-left: 0;
    padding: 8px 12px;
    width: fit-content;
    text-decoration: none;
    color: #121314;
  }
`;

const CellLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    font-weight: 600;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
    color: #121314;
  }
`;

const STOCK_COLUMS = [
  " ",
  "종목",
  "참여랭커",
  "Top 트레이더",
  "평균 수익률",
  "평균 수익금",
];

const StockSection = () => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);
  const { data: hotstockData = {} } = useHotStockQuery({
    cid: selectedCompetition?.cid,
  });

  return (
    <TagWrapper>
      <StyledTable>
        <thead>
          <tr>
            {STOCK_COLUMS.map((column, index) => (
              <StyledHeader key={index}>
                <div className="item">{column}</div>
              </StyledHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {hotstockData &&
            hotstockData?.stocks?.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledCell>
                  <IndexNumber text={index + 1} />
                </StyledCell>
                <StyledCell>
                  <CellLink to={`/hotstock/${row?.id || ""}`}>
                    <div className="itemContainer" style={itemContainerStyle}>
                      <div className="item itemLink">
                        <SvgStkImg code={row.code} name={row.name} />
                      </div>
                      <div className="item itemLink">
                        {unescapeHtml(row.name)}
                      </div>
                    </div>
                  </CellLink>
                </StyledCell>
                <StyledCell>
                  <div className="item">{row.n_ranker + "명"}</div>
                </StyledCell>
                <StyledCell>
                  <div className="item">
                    {row.top_ret_trader_nick}
                    <span style={{ color: "#FF2727", marginLeft: 4 }}>
                      {row.top_ret > 0 ? "+" : ""}
                      {row.top_ret.toFixed(2) + "%"}
                    </span>
                  </div>
                </StyledCell>
                <StyledCell>
                  <div className="item">{row.avg_ret.toFixed(2)}%</div>
                </StyledCell>
                <StyledCell>
                  <div className="item">{convertToNearWon(row.avg_pnl)}</div>
                </StyledCell>
              </StyledTableRow>
            ))} 
        </tbody>
      </StyledTable>
    </TagWrapper>
  );
};

export default StockSection;
