import { Tooltip } from "react-tooltip";
import styled from "styled-components";
const InfoSection = styled.div`
  display: flex;

  justify-content: right;
`;

const InfoWrapper = styled.div`
  height: 20px;
  color: #afb0b2;
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.13px;
`;

const InfoName = styled.div`
  font-size: 12px;
  color: #969799;
`;

const InfoData = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: #121314;
`;

const TooltipWrapper = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.13px;
  text-align: right;

  .title {
    font-size: 12px;
    font-weight: 600;
  }
`;

const DataInfoLine = () => {
  return (
    <InfoSection>
      <InfoWrapper>
        <div data-tooltip-id="datainfo-tooltip">
          ※ 알파노트에서 불러온 데이터는 실제 데이터와 차이가 있을 수 있습니다.
        </div>
      </InfoWrapper>
      <Tooltip id="datainfo-tooltip" place="bottom">
        <TooltipWrapper>
          <div className="title">타이틀</div>
          <div>* 내용</div>
          <div>* 내용</div>
          <div>* 내용</div>
        </TooltipWrapper>
      </Tooltip>
    </InfoSection>
  );
};

export default DataInfoLine;
