import { ReactComponent as ArrowLeftIcon } from "assets/images/ico_arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/ico_arrow_right.svg";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  convertToPercentage,
  convertToWon,
  formatSec,
  formatTimestampToKoYMD,
} from "utils/utils";
import AlphaTag from "./AlphaTag";
import MyApexChart from "./MyApexChart";

const CardSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;

  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const LeftRoundButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e7e9ef;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 14px 0px #767d9217;
  margin-right: 16px;

  &:hover {
    background-color: #f7f8fa;
  }

  &:active {
    background-color: #e7e9ef;
    border: 1px solid #dcdee3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
const RightRoundButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #e7e9ef;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  box-shadow: 0px 4px 14px 0px #767d9217;
  margin-left: 16px;

  &:hover {
    background-color: #f7f8fa;
  }

  &:active {
    background-color: #e7e9ef;
    border: 1px solid #dcdee3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ChartCardWrapper = styled.div`
  position: relative;
  width: 1127px;
  height: 487px;
  border: 1px solid #ebedf5;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-radius: 8px 0 0 0;
    border-top: 70px solid #fff2ae;
    border-right: 70px solid transparent;
  }
`;

const ChartWrapper = styled.div`
  margin-top: 132px;
  padding: 0 24px 24px;
`;

const TriangleIndex = styled.div`
  font-family: Montserrat;
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: 20.72px;

  position: absolute;
  top: 12px;
  left: 18px;
`;

const ReportInfo = styled.div`
  position: absolute;
  top: 24px;
  left: 56px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  span {
    font-weight: 600;
  }
`;

const ReportDetail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;

  position: absolute;
  top: 74px;
  left: 24px;
`;

const ReportLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Pretendard;
  line-height: 15.48px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: left;

  color: #969799;

  span {
    margin-top: 5px;
    font-size: 19px;
    font-weight: 500;
    line-height: 22.67px;
    text-align: left;
    color: #121314;
    font-weight: 600;
  }
`;

const StockDetail = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  position: absolute;
  top: 80px;
  right: 36px;
`;

const StockInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-left: 1px solid RGBA(0, 0, 0, 0.05);
  padding-left: 12px;
`;

const StockLine = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.48px;
    text-align: left;
    color: #767d92;

    span {
        width: 100%;
        color: #121314;
        margin-left: 22px;
    }

    .rank {
      margin-left: 45px;
    }
};`;

function LeftButton({ onClick, disabled }) {
  return (
    <LeftRoundButton onClick={onClick} disabled={disabled}>
      <ArrowLeftIcon />
    </LeftRoundButton>
  );
}

const RightButton = ({ onClick, disabled }) => {
  return (
    <RightRoundButton onClick={onClick} disabled={disabled}>
      <ArrowRightIcon />
    </RightRoundButton>
  );
};

const ScrollGanttSection = ({ data, width = 1129 }) => {
  const ganttSectionRef = useRef();

  const handleCardClick = (id) => {
    window.location.href = `/trader/${id}`;
  };

  const handleButtonClick = (type) => {
    const widthWithGap = width + 24;
    const scrollWay = type === "left" ? 1 : -1;
    const currentIndex = Math.floor(
      ganttSectionRef?.current?.scrollLeft / widthWithGap
    );

    ganttSectionRef.current.scrollTo({
      left: (currentIndex + scrollWay) * widthWithGap,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    ganttSectionRef.current.scrollTo({
      left: 0,
      behavior: "instant",
    });
  }, [data]);

  return (
    <CardSectionWrapper>
      <LeftButton onClick={() => handleButtonClick("right")} disabled={false} />
      <CardSection ref={ganttSectionRef}>
        {data &&
          data?.traders?.map((item, index) => (
            <ChartCardWrapper key={index}>
              <TriangleIndex>{index + 1}</TriangleIndex>
              <ReportInfo>
                <span>{formatTimestampToKoYMD(new Date(item?.date))}</span> |{" "}
                {item?.nick}
                <AlphaTag type={item.league}>{item.league}</AlphaTag>
                <AlphaTag type={item.style}>
                  {TRADE_STYLE_TYPE[item.style]}
                </AlphaTag>
              </ReportInfo>
              <ReportDetail>
                <ReportLine>
                  <div>수익률</div>
                  <span>{convertToPercentage(item.ret)}</span>
                </ReportLine>
                <ReportLine>
                  <div>수익금</div>
                  <span>{convertToWon(item.pnl)}</span>
                </ReportLine>
              </ReportDetail>
              <StockDetail>
                <StockInfo>
                  <StockLine>
                    거래종목<span>{item.n_stock}종목</span>
                  </StockLine>
                  <StockLine>
                    거래대금<span>{convertToWon(item.amt)}</span>
                  </StockLine>
                </StockInfo>
                <StockInfo>
                  <StockLine>
                    매매건수<span>{item.n_tr}건</span>
                  </StockLine>
                  <StockLine>
                    체결건수<span>{item.n_trx}건</span>
                  </StockLine>
                </StockInfo>
                <StockInfo>
                  <StockLine>
                    평균보유시간<span>{formatSec(item.avg_hold_time)}</span>
                  </StockLine>
                  <StockLine>
                    대회랭킹
                    <span className="rank">
                      {item.rank === -1 ? "현재 순위 없음" : item.rank + "위"}
                    </span>
                  </StockLine>
                </StockInfo>
              </StockDetail>
              <ChartWrapper>
                <MyApexChart
                  data={item.chart}
                  height={331}
                  noteIdList={item.chart.map((item) => item.note_id)}
                />
              </ChartWrapper>
            </ChartCardWrapper>
          ))}
      </CardSection>
      <RightButton onClick={() => handleButtonClick("left")} disabled={false} />
    </CardSectionWrapper>
  );
};

export default ScrollGanttSection;
