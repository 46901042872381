import styled from "styled-components";

const IndexStyle = styled.div`
  font-family: Montserrat;
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  line-height: 18.29px;
  color: #141414;

  text-align: left;
  margin-left: 24px;
`;
const IndexNumber = ({ text = "" }) => {
  return <IndexStyle>{text}</IndexStyle>;
};

export default IndexNumber;
