import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAlphaRankQuery({ cid = "", type = "league" }) {
  const httpClient = useHttpClient(api_v_url);

  const qk = type === "league" ? "trader-league_rank" : "trader-style_rank";
  const apiurl =
    type === "league"
      ? `/v1b/trader/league_ranker?cid=${cid}`
      : `/v1b/trader/style_ranker?cid=${cid}`;

  return useQuery({
    queryKey: [qk, { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        // `${apiurl}?cid=${cid}`
        `${apiurl}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaLeagueRankQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-league_rank", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/trader/league_rank?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}
function useAlphaStyleRankQuery({ cid = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-style_rank", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/trader/style_rank?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export { useAlphaLeagueRankQuery, useAlphaRankQuery, useAlphaStyleRankQuery };
