import { ReactComponent as SearchIcon } from "assets/images/ico_search.svg";
import AlphaRangeSelect from "components/AlphaRangeSelect";
import { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import base64 from "base-64";
import styled from "styled-components";

const Header = styled.header`
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: ${(props) =>
    props.isScrolled
      ? props.is_white === "white"
        ? "1px solid #F2F4F7"
        : "1px solid rgba(0, 0, 0, 0.1)"
      : "none"};
  background: ${(props) => (props.is_white === "white" ? "ffffff" : "#16191F")};
  color: ${(props) => (props.is_white === "white" ? "#4B4C4D" : "#fff")};
  height: 60px;
`;
const Nav = styled.nav`
  max-width: 1680px;
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .fit {
    flex: 1 1;
  }

  .logo {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .login {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`;

const LogoLink = styled(Link)`
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 800;
  line-height: 22.67px;
  text-align: center;
  color: ${(props) => (props.is_white === "white" ? "#000000" : "#fff")};
  text-decoration: none;
  width: 113px;
`;

const NavigationList = styled.ul`
  list-style: none;
  display: flex;

  padding-inline-start: 16px;
  height: 100%;
  margin: 0;
`;

const NavigationItem = styled.li`
  align-self: center;
`;

const NavigationLoginItem = styled.div`
  margin-left: 6px;
  color: #fff;
`;

const NavigationLink = styled(Link)`
  padding: 14px;
  text-decoration: none;
  color: ${(props) =>
    props.is_white === "white"
      ? props.is_active === "active"
        ? "#000000"
        : "#4B4C4D"
      : props.is_active === "active"
      ? "#FFFFFF"
      : "#969799"};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: ${(props) => (props.is_active === "active" ? "700" : "400")};
  line-height: 17.9px;
  text-align: center;

  &:hover {
    color: ${(props) => (props.is_white === "white" ? "#000000" : "#fff")};
  }
`;

const SearchInput = styled.input`
  padding: 0 12px;
  border-radius: 58px;
  border: 1px solid #efefef;
  background: #f5f5f7;
  font-size: 1rem;
  height: 36px;
`;

const NavigationRightSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;

  svg {
    width: 20px;
    height: 20px;
    padding: 10px;

    cursor: pointer;
  }
`;

const Competition = styled.div`
  height: 40px;
  padding: 0 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.3);

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;

  color: #191a1a;

  svg {
    margin-left: 8px;
  }
`;

const TopNavigation = () => {
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();

  const hideTopNavigation = location.pathname.startsWith("/notee");

  const activeUrl = location.pathname;
  const WHITE_GNB = ["/hotstock", "/trader", "/competition", "/note"];
  const isWhiteGNB = WHITE_GNB.includes(activeUrl) ? "white" : "black";
  const isCompetition =
    activeUrl.includes("/competition") ||
    activeUrl.endsWith("/trader") ||
    activeUrl.endsWith("/hotstock");
  const isNoCompetition = !isCompetition;
  const isActive = (url) => (activeUrl.includes(url) ? "active" : "deactive");

  // console.log("activeUrl", activeUrl);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    //  check is token expired
    if (token) {
      const payload = JSON.parse(base64.decode(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      if (payload.exp < currentTime) {
        localStorage.removeItem("access_token");
      } else {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "login",
          user_id: payload.id,
          arpa_id: payload.id,
        });
        setIsLoggedIn(!!token);
      }
    }

    const handleStorageChange = () => {
      const token = localStorage.getItem("access_token");
      setIsLoggedIn(!!token);
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  if (hideTopNavigation) {
    return null;
  }

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedSearch = debounce(async (term) => {
    try {
      setLoading(true);
      // const response = await axios.get(
      //   `https://api.example.com/search?q=${term}`
      // );
      // setSearchResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setLoading(false);
    }
  }, 300);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    const loginWindow = window.open("/login", "Login", "width=500,height=600");

    const checkWindowClosed = setInterval(() => {
      if (loginWindow.closed) {
        clearInterval(checkWindowClosed);
        const token = localStorage.getItem("access_token");
        setIsLoggedIn(!!token);
      }
    }, 500);
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("access_token");
    setIsLoggedIn(false);

    window.location.reload();
  };

  return (
    <Header>
      <NavWrapper is_white={isWhiteGNB} isScrolled={isScrolled}>
        <Nav>
          <div className="fit logo">
            <LogoLink is_white={isWhiteGNB} to="/">
              AlphaNote
            </LogoLink>
            <NavigationList>
              <NavigationItem>
                <NavigationLink
                  is_white={isWhiteGNB}
                  is_active={isActive("/hotstock")}
                  to="/hotstock"
                >
                  주도주
                </NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink
                  is_white={isWhiteGNB}
                  is_active={isActive("/trader")}
                  to="/trader"
                >
                  트레이더
                </NavigationLink>
              </NavigationItem>
              {/* <NavigationItem>
                <NavigationLink
                  is_white={isWhiteGNB}
                  is_active={isActive("/competition")}
                  to="/competition"
                >
                  대회분석
                </NavigationLink>
              </NavigationItem> */}
            </NavigationList>
          </div>
          <div>
            {!isNoCompetition && (
              <AlphaRangeSelect isDarkMode={isWhiteGNB === "black"} />
            )}
          </div>
          <div className="fit login">
            <NavigationRightSection>
              <SearchIcon />
              <NavigationLoginItem>
                <NavigationLink
                  is_white={isWhiteGNB}
                  target="_blank"
                  to="https://www.notion.so/arpa/4f30be1936434fb59c4d3c80f779841f?pvs=4"
                >
                  알파노트 구독
                </NavigationLink>
              </NavigationLoginItem>
              <NavigationLoginItem>
                {isLoggedIn ? (
                  <NavigationLink
                    is_white={isWhiteGNB}
                    onClick={handleLogoutClick}
                  >
                    로그아웃
                  </NavigationLink>
                ) : (
                  <NavigationLink
                    is_white={isWhiteGNB}
                    is_active={isActive("/login")}
                    to="/login"
                    onClick={handleLoginClick}
                  >
                    로그인
                  </NavigationLink>
                )}
              </NavigationLoginItem>
            </NavigationRightSection>
          </div>
        </Nav>
      </NavWrapper>
    </Header>
  );
};

export default TopNavigation;
