import { ReactComponent as InfoIcon } from "assets/images/ico_info.svg";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
const InfoSection = styled.div`
  display: flex;
  justify-content: right;
`;

const InfoWrapper = styled.div`
  height: 20px;
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.13px;
`;

const TooltipWrapper = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.13px;

  .tooltip-title {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 11px;
  }
`;

const ToolTipInfoIcon = ({
  title = "",
  body = [],
  tooltipKey = "tooltip-id",
}) => {
  return (
    <InfoSection>
      <InfoWrapper>
        <InfoIcon data-tooltip-id={tooltipKey} />
      </InfoWrapper>
      <Tooltip id={tooltipKey} place="bottom">
        <TooltipWrapper>
          <div className="tooltip-title">{title}</div>
          {body?.map((content, idx) => (
            <div key={idx}>{content}</div>
          ))}
        </TooltipWrapper>
      </Tooltip>
    </InfoSection>
  );
};

export default ToolTipInfoIcon;
