import useHttpClient from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAllInOneQuery() {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["common-all_in_one"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(`/common/all_in_one`);

      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

export { useAllInOneQuery };
