import AlphaTop from "assets/images/home_alpha_top.png";
import AlphaTopHover from "assets/images/home_alpha_top_hover.png";
import LeagueTop from "assets/images/home_league_top.png";
import LeagueTopHover from "assets/images/home_league_top_hover.png";
import StyleTop from "assets/images/home_style_top.png";
import StyleTopHover from "assets/images/home_style_top_hover.png";
import Illustrator2 from "assets/images/hotstock_illustration.png";
import Illustrator1 from "assets/images/trader_illustration.png";

import { ReactComponent as ArrowUp } from "assets/images/ico_arrow_dropdown_up.svg";
import EmblaCarousel from "components/EmblaCarousel";
import { SvgStkImg } from "components/StockIcon";
import { useHotStocksQuery } from "hooks/queries/useHomeQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TRADE_STYLE } from "utils/consts";
import { useSelectedCompetition } from "utils/store";
import { convertToPercentage, convertToWon } from "utils/utils";

const ArrowDown = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: "rotate(180deg)" }}
  >
    <path
      d="M6.8 3.56667L9.3 6.9C9.79443 7.55924 9.32404 8.5 8.5 8.5L3.5 8.5C2.67595 8.5 2.20557 7.55924 2.7 6.9L5.2 3.56667C5.6 3.03333 6.4 3.03333 6.8 3.56667Z"
      fill="#4c67ff"
    />
  </svg>
);
const BackgroundWrapper = styled.div`
  background-color: ${(props) => props.color || "#fff"};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const BlackSectionWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 780px;
  position: relative;

  img {
    width: 456px;
    height: 360px;
    top: 31px;
    right: 0;
    position: absolute;
  }
`;
const BlackInfoSection = styled.div`
  padding: 80px 0 85px;
  color: #fff;
  display: flex;
  flex-direction: column;

  max-width: 1128px;
  width: 100%;
  position: relative;
  font-family: Pretendard;

  .title {
    font-size: 56px;
    font-weight: 500;
    line-height: 69.44px;
    text-align: left;
    margin-bottom: 20px;
  }

  .description {
    font-size: 17px;
    font-weight: 400;
    line-height: 27.2px;
    text-align: left;
    color: #f7f8fa;
    margin-bottom: 28px;
    opacity: 0.44;
  }

  .traderButton {
    width: fit-content;
    padding: 12.5px 20px;
    border-radius: 8px;
    background-color: #ff3e61;

    font-size: 18px;
    font-weight: 700;
    line-height: 21.48px;
    text-align: left;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
`;

const WhiteSectionWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 504px;
  position: relative;

  img {
    width: 456px;
    height: 360px;
    top: 50px;
    left: 0;
    position: absolute;
  }
`;
const WhiteInfoSection = styled.div`
  padding: 90px 0 121px;
  color: #121314;
  display: flex;
  flex-direction: column;

  max-width: 1128px;

  position: relative;
  font-family: Pretendard;

  float: right;
  width: fit-content;

  .title {
    font-size: 56px;
    font-weight: 600;
    line-height: 71.68px;
    text-align: left;
    margin-bottom: 12px;
  }

  .description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: left;

    color: #121314;
    margin-bottom: 33px;
    opacity: 0.6;
  }

  .traderButton {
    width: fit-content;
    padding: 12.5px 20px;
    border-radius: 8px;
    background-color: #ff3e61;

    font-size: 18px;
    font-weight: 700;
    line-height: 21.48px;
    text-align: left;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
`;

const TopCardSectionWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 16px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TopCardSection = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  color: #fff;
`;

const TopCard = styled.div`
  width: 360px;
  height: 250px;
  background: #22262e;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-family: Pretendard;
    font-size: 23px;
    font-weight: 600;
    line-height: 27.45px;
    text-align: left;
    margin-bottom: 18px;
  }

  .description {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    opacity: 0.5;
  }

  .tagWrapper {
    display: flex;
    gap: 8px;
  }

  .tag {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    opacity: 0.55;

    padding: 7px 14px;
    background: #9da8be29;
    border-radius: 32px;
  }
`;

const StockCardSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  color: #000;
  margin-bottom: 131px;
`;

const StockCardWrapper = styled.div`
  width: 340px;
  height: 220px;
  background: #ffffff;
  border-radius: 18px;
  box-sizing: border-box;
  padding: 28px;

  display: flex;
  flex-direction: column;
  font-family: Pretendard;
  cursor: pointer;

  .titleWrapper {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    margin-left: 8px;
  }

  .descriptionWrapper {
    margin-top: 24px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .descriptionCell {
    display: flex;
    justify-content: space-between;
  }

  .description {
    opacity: 0.8;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
  }

  .value {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;

    color: #ff2727;
  }

  .price {
    font-size: 24px;
    font-weight: 400;
    line-height: 28.64px;
    text-align: right;
  }

  .summary {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: left;
    margin-top: auto;

    span {
      font-weight: 700;
    }
  }

  .negative {
    color: #4c67ff;
  }
`;

const TopCardImage = styled.div`
  width: 360px;
  height: 250px;

  background-image: url(${(props) => props.defaultImage});
  background-size: cover;
  cursor: pointer;

  &:hover {
    background-image: url(${(props) => props.hoverImage});
    transition: background-image 0.3s ease-in-out;
  }
`;

export const StockCard = ({
  code = "",
  name = "",
  daily_change = 0,
  daily_change_ratio = 0,
  close = 0,
  n_ranker = 0,
  total = 0,
}) => {
  const status = daily_change >= 0 ? "positive" : "negative";
  const navigate = useNavigate();

  return (
    <StockCardWrapper onClick={() => navigate(`/hotstock#${code}`)}>
      <div className="titleWrapper">
        <SvgStkImg code={code} name={name} size={30} />
        <div className="title">{name}</div>
      </div>
      <div className="descriptionWrapper">
        <div className="descriptionCell">
          <div className="description">종가</div>
          <div className={`value price ${status}`}>{convertToWon(close)}</div>
        </div>
        <div className="descriptionCell">
          <div className="description">전일대비</div>

          <div className={`value ${status}`}>
            {daily_change >= 0 ? <ArrowUp /> : <ArrowDown />}
            {`${convertToWon(Math.abs(daily_change))} (${
              daily_change >= 0 ? "+" : ""
            }${convertToPercentage(daily_change_ratio)})`}
          </div>
        </div>
      </div>
      <div className="summary">
        {total}명 중 <span>{n_ranker}명</span>이 거래했어요.
      </div>
    </StockCardWrapper>
  );
};

const AlphaHome = () => {
  const navigate = useNavigate();
  const [treaderType, setTraderType] = useState(TRADE_STYLE[0].type);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  const { selectedCompetition } = useSelectedCompetition((state) => state);
  const cid = selectedCompetition?.cid;
  const param = cid ? { cid } : {};

  const OPTIONS = { loop: true };

  const { data: hotStocks } = useHotStocksQuery();

  console.log("hotStocks", hotStocks);
  // const { data: favTraderData = [] } = useFavTraderQuery(param);
  // const { data: favNoteData = [] } = useFavNoteQuery(param);
  // const { data: leagueTop = [] } = useLeagueTopQuery(param);

  return (
    <Wrapper>
      <BackgroundWrapper color="#16191F">
        <BlackSectionWrapper>
          <BlackInfoSection>
            <div className="title">
              진짜 ‘매매’를 잘하는
              <br />
              트레이더를 소개합니다.
            </div>
            <div className="description">
              수익률뿐만 아니라 다양한 방식으로 선정한 트레이더를 소개합니다.
              <br />
              베일에 싸여있던 상위 1% 트레이더들의 특징을 상세하게 파악해
              보세요.
            </div>
            <div className="traderButton" onClick={() => navigate("/trader")}>
              트레이더 보러가기
            </div>
          </BlackInfoSection>

          <TopCardSection>
            <TopCardImage
              defaultImage={LeagueTop}
              hoverImage={LeagueTopHover}
              onClick={() => navigate("/trader#league")}
            />
            <TopCardImage
              defaultImage={StyleTop}
              hoverImage={StyleTopHover}
              onClick={() => navigate("/trader#style")}
            />
            <TopCardImage
              defaultImage={AlphaTop}
              hoverImage={AlphaTopHover}
              onClick={() => navigate("/trader#alpha")}
            />
            {/* <TopCard>
              <div className="textWrapper">
                <div className="title">매매 스타일</div>
                <div className="description">
                  당신의 매매 스타일을
                  <br />
                  선택해보세요.
                </div>
              </div>
              <div className="tagWrapper">
                <div className="tag">#태그</div>
                <div className="tag">#태그</div>
              </div>
            </TopCard>
            <TopCard>
              <div className="textWrapper">
                <div className="title">매매 스타일</div>
                <div className="description">
                  당신의 매매 스타일을
                  <br />
                  선택해보세요.
                </div>
              </div>
              <div className="tagWrapper">
                <div className="tag">#태그</div>
                <div className="tag">#태그</div>
              </div>
            </TopCard>
            <TopCard>
              <div className="textWrapper">
                <div className="title">매매 스타일</div>
                <div className="description">
                  당신의 매매 스타일을
                  <br />
                  선택해보세요.
                </div>
              </div>
              <div className="tagWrapper">
                <div className="tag">#태그</div>
                <div className="tag">#태그</div>
              </div>
            </TopCard> */}
          </TopCardSection>
          <img src={Illustrator1} />
        </BlackSectionWrapper>
      </BackgroundWrapper>
      <BackgroundWrapper color="#F2F4F7">
        <WhiteSectionWrapper>
          <WhiteInfoSection>
            <div className="title">
              트레이더들이 가장 많이
              <br />
              거래한 종목에 주목해 보세요.
            </div>
            <div className="description">
              매일 상위 트레이더 500명이 가장 많이 거래한 10개 종목을
              소개합니다.
              <br />
              종목 별로 공략을 가장 잘 한 트레이더들의 매매일지 ‘알파노트’를
              학습해 보세요.
            </div>
            <div className="traderButton" onClick={() => navigate("/hotstock")}>
              주도주 보러가기
            </div>
          </WhiteInfoSection>

          <img src={Illustrator2} />
        </WhiteSectionWrapper>

        <StockCardSection>
          {hotStocks && (
            <EmblaCarousel
              slides={hotStocks?.stocks?.map((item) => ({
                name: item.name,
                daily_change: item.daily_change,
                daily_change_ratio: item.daily_change_ratio,
                n_ranker: item.n_ranker,
                close: item.close,
                code: item.code,
              }))}
              options={OPTIONS}
            />
          )}
        </StockCardSection>
      </BackgroundWrapper>
    </Wrapper>
  );
};

export default AlphaHome;
