import { useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 4px;

  height: 24px;
  padding: 4px;

  border-radius: 6px;
  background: #f2f4f7;
`;

const ToggleItem = styled.div`
  flex: 1;
  padding: 4px 10px;

  border-radius: 4px;
  min-width: fit-content;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: ${(props) => (props.active === "true" ? "500" : "400")};
  line-height: 16px;
  letter-spacing: 0em;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => (props.active === "true" ? "#FFFFFF" : "")};
  color: ${(props) => (props.active === "true" ? "#000000" : "#191A1A")};
  opacity: ${(props) => (props.active === "true" ? "1" : "0.4")};
  cursor: pointer;

  box-shadow: ${(props) =>
    props.active === "true" ? " 0px 0px 2px 0px #0000001A" : "0"};
`;

const ToggleComponet = ({
  onClick,
  labels = [
    { name: "시간별", type: "time" },
    { name: "스타일별", type: "style" },
  ],
}) => {
  const [activeIndex, setActiveIndex] = useState(labels[0].type);

  return (
    <ToggleWrapper>
      {labels.map((item, index) => (
        <ToggleItem
          key={index}
          active={(item.type === activeIndex).toString()}
          onClick={() => {
            setActiveIndex(item.type);
            onClick(item.type);
          }}
        >
          {item.name}
        </ToggleItem>
      ))}
    </ToggleWrapper>
  );
};

export default ToggleComponet;
