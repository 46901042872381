import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Header = styled.header`
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust z-index as needed */
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: ${(props) =>
    props.is_white === "white" ? "#F9FAFC" : "#121314"};
  color: ${(props) => (props.is_white === "white" ? "#4B4C4D" : "#fff")};
  height: 55px;
`;

const Nav = styled.nav`
  max-width: 1128px;
  width: 100%;

  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 800;
  line-height: 22.67px;
  text-align: center;
  color: ${(props) => (props.is_white === "white" ? "#000000" : "#fff")};
  text-decoration: none;
  width: 113px;
`;

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  margin-right: auto;
  padding-inline-start: 16px;
  height: 100%;
`;

const NavigationItem = styled.li`
  padding: 0 14px;
  align-self: center;
`;

const NavigationLoginItem = styled.div`
  margin-left: 16px;
  color: #fff;
`;

const NavigationLink = styled(Link)`
  text-decoration: none;
  color: ${(props) =>
    props.is_white === "white"
      ? props.is_active === "active"
        ? "#000000"
        : "#4B4C4D"
      : props.is_active === "active"
      ? "#FFFFFF"
      : "#969799"};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: ${(props) => (props.is_active === "active" ? "700" : "400")};
  line-height: 17.9px;
  text-align: center;

  &:hover {
    color: ${(props) => (props.is_white === "white" ? "#000000" : "#fff")};
  }
`;

const SearchInput = styled.input`
  padding: 0 12px;
  border-radius: 58px;
  border: 1px solid #efefef;
  background: #f5f5f7;
  font-size: 1rem;
  height: 36px;
`;

const NavigationRightSection = styled.div`
  display: flex;
  align-items: center;
`;

const TopNavigation = () => {
  const searchInputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const hideTopNavigation = location.pathname.startsWith("/note");

  const activeUrl = location.pathname;
  // const WHITE_GNB = ["/trader", "/hotstock"];
  const WHITE_GNB = ["/hotstock", "/trader", "/competition"];
  const isWhiteGNB = WHITE_GNB.includes(activeUrl) ? "white" : "black";
  const isActive = (url) => (activeUrl.includes(url) ? "active" : "deactive");

  if (hideTopNavigation) {
    return null;
  }

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const debouncedSearch = debounce(async (term) => {
    try {
      setLoading(true);
      // const response = await axios.get(
      //   `https://api.example.com/search?q=${term}`
      // );
      // setSearchResults(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setLoading(false);
    }
  }, 300);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  return (
    <Header>
      <NavWrapper is_white={isWhiteGNB}>
        <Nav>
          <LogoLink is_white={isWhiteGNB} to="/">
            AlphaNote
          </LogoLink>
          <NavigationList>
            <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                is_active={isActive("/hotstock")}
                to="/hotstock"
              >
                주도주
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                is_active={isActive("/trader")}
                to="/trader"
              >
                트레이더
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                is_active={isActive("/competition")}
                to="/competition"
              >
                대회분석
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                target="_blank"
                // is_active={isActive("/detail")}
                to="https://arpa.notion.site/c15ef9573fcb4f7197fd60488aaf6413?v=f37e113d9533408aa419cef30d505cf4&pvs=4"
              >
                리포트
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                target="_blank"
                // is_active={isActive("/detail")}
                to="https://www.notion.so/arpa/4f30be1936434fb59c4d3c80f779841f?pvs=4"
              >
                고객센터
              </NavigationLink>
            </NavigationItem>
            {/* <NavigationItem>
              <NavigationLink
                is_white={isWhiteGNB}
                is_active={isActive("/login")}
                to="/login"
              >
                로그인
              </NavigationLink>
            </NavigationItem> */}
            {/* <NavigationItem>
              <NavigationLink to="/detail">매매일지</NavigationLink>
            </NavigationItem> */}
          </NavigationList>
          <NavigationRightSection>
            {/* <SearchInput
              type="text"
              placeholder="트레이더, 종목 검색"
              onChange={handleSearchChange}
              ref={searchInputRef}
            />
            <NavigationLoginItem>
              <NavigationLink to="/">로그인</NavigationLink>
            </NavigationLoginItem> */}
          </NavigationRightSection>
        </Nav>
      </NavWrapper>
    </Header>
  );
};
export default TopNavigation;
