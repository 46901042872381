import styled from "styled-components";

import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

const ChartWrapper = styled.div`
  width: 100%;
`;

const ChartInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoValue = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;
  text-align: left;
  display: flex;

  .red {
    color: #ff3a6a;
    margin-left: 3px;
    font-weight: 600;
  }

  .gray {
    color: #ebedf5;
    margin-left: 3px;
    font-weight: 600;
  }
`;

export const WinningGraph = ({ win = 0, lose = 0 }) => {
  return (
    <ChartWrapper>
      <ChartInfo>
        <InfoValue>
          <div>승리</div>
          <div className="red">{win}</div>
        </InfoValue>
        <InfoValue>
          <div>패배</div>
          <div className="gray">{lose}</div>
        </InfoValue>
      </ChartInfo>
      <ResponsiveContainer width={"100%"} height={14}>
        <BarChart
          stackOffset="expand"
          data={[{ name: "", win: win, lose: lose }]}
          layout="vertical"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis type="number" hide={true} />
          <YAxis type="category" dataKey="name" hide={true} />
          <Bar
            dataKey="win"
            stackId="a"
            fill="#FF3A6A"
            barSize={4}
            radius={[4, 0, 0, 4]}
          />
          <Bar
            dataKey="lose"
            stackId="a"
            fill="#EBEDF5"
            barSize={4}
            radius={[0, 4, 4, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};
