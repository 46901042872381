import TraderLeagueToggle from "components/TraderLeagueToggle";
import TraderStockLeagueTableSection from "components/TraderStockLeagueTableSection";
import { ChartArea } from "pages/TraderDetail/StockDetail";
import { useState } from "react";
import styled from "styled-components";
import { unescapeHtml } from "utils/utils";

const SectionWrapper = styled.div`
  margin: 42px 0 70px;
`;

const ToggleWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.9px;
  text-align: left;

  margin-bottom: 9px;
`;

const ChartWrapper = styled.div`
  width: 100%;
`;

const LeagueWrapper = styled.div`
  width: 100%;
  max-width: 1128px;
`;

const ChartInfoText = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.28px;
  text-align: right;
  color: #969799;
`;
const LeagueSection = ({ selectedCard = 0, leagueData = [] }) => {
  const [league, setLeague] = useState("1억");

  const selectedIdx = 0; // 트레이더 선택 idx
  const graphData = {
    chart: leagueData?.chart,
    markers: leagueData?.participant[league]?.[selectedIdx]?.markers,
  };
  const rankerData = leagueData?.participant[league];

  return (
    <LeagueWrapper>
      <ToggleWrapper>
        <TraderLeagueToggle
          onClick={(type) => {
            setLeague(type);
          }}
        />
      </ToggleWrapper>
      {graphData && (
        <ChartWrapper>
          <ChartArea
            chartData={graphData?.chart}
            markersData={graphData?.markers}
            name={unescapeHtml(graphData?.chart?.name || "")}
          />
          <ChartInfoText>
            *{league} 리그 1등 트레이더의 Buy&Sell 포인트입니다.
          </ChartInfoText>
        </ChartWrapper>
      )}
      <SectionWrapper>
        <SectionTitle>대표 트레이더</SectionTitle>
        <TraderStockLeagueTableSection dataList={rankerData} />
      </SectionWrapper>
    </LeagueWrapper>
  );
};

export default LeagueSection;
