import { useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 52px;
  position: relative;

  .toggle-background {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px 8px 0 0;
    border: 1.5px solid #dcdee3;
    border-bottom: 0;
  }
`;

const ToggleItem = styled.div`
  z-index: 10;
  box-sizing: border-box;
  font-family: Pretendard;
  font-size: 15px;
  line-height: 17.9px;
  text-align: center;

  border-radius: 8px 8px 0 0;

  font-weight: ${(props) => (props.active === "true" ? "600" : "400")};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => (props.active === "true" ? "#000000" : "#969799")};
  cursor: pointer;

  border: ${(props) => (props.active === "true" ? "1.5px solid #000000" : "")};
  border-bottom: ${(props) =>
    props.active === "true" ? "1.5px solid #ffffff" : "1.5px solid #000000"};

  flex: 1;
`;

const AlphaTradeListToggle = ({
  setToggleState,
  tabNames = ["리그별 랭킹", "스타일별 랭킹", "알파노트"],
  toggleState = ["league", "style", "alphaNote"],
  type = "league",
}) => {
  const givenIndex = toggleState.findIndex((state) => state === type);
  const [activeIndex, setActiveIndex] = useState(givenIndex === -1 ? 0 : givenIndex);

  return (
    <ToggleWrapper>
      <div className="toggle-background" />
      {tabNames.map((item, index) => (
        <ToggleItem
          key={index}
          active={(index === activeIndex).toString()}
          onClick={() => {
            setActiveIndex(index);
            setToggleState(toggleState[index]);
          }}
        >
          {item}
        </ToggleItem>
      ))}
    </ToggleWrapper>
  );
};

export default AlphaTradeListToggle;
