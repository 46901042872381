import bgImage from "assets/images/bg_header_hotstock.png";
import EmptyLogo from "assets/images/empty_logo.png";
import TraderNoteTitle from "assets/images/traderlist_notetitle.png";
import TraderStyleTitle from "assets/images/traderlist_styletitle.png";
import TraderListTitle from "assets/images/traderlist_title.png";
import { useEffect, useState } from "react";
import { useNotice } from "utils/store";

import AlphaTradeListToggle from "components/AlphaTradeListToggle";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import AlphaTraderRanking from "./AlphaTraderRanking";

const Title = styled.div`
  color: #000000;

  font-family: Pretendard;

  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  margin-bottom: 4px;
`;
const Description = styled.div`
  color: #000;
  opacity: 0.6;
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
  text-align: left;
  margin-bottom: 16px;
`;
const TitleSection = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 44px 0 58px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tooltip {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 90%;
    width: 210px;
  }

  .example {
    width: 210px;

    font-family: Pretendard;
    font-size: 13px;
    font-weight: 600;
    line-height: 15.51px;
    text-align: left;
    color: #ffffff;

    p {
      font-weight: 400;
      color: #c8c9cc;
      margin-bottom: 0;
    }
  }

  .title {
    margin: 26px 0 57px 0;
    font-family: Pretendard;
    font-size: 42px;
    font-weight: 600;
    line-height: 54.6px;
    text-align: center;
  }

  .title img {
    width: 888px;
  }
`;

// const SectionWrapper = styled.div`
//   width: 1240px;
// `;

const SectionWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
`;

const ToggleWrapper = styled.div`
  width: 210px;
`;

const TextWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 236px 0;
  align-items: center;
  color: #afb0b2;

  img {
    width: 30px;
    margin-bottom: 10px;
  }
`;

const getHashValue = () => {
  const hash = window.location?.hash.replace("#", "");
  const validHashes = ["league", "style", "alphaNote"];

  if (validHashes.includes(hash)) {
    return hash;
  } else {
    return "league";
  }
};

const AlphaTraderList = () => {
  const hash = window.location.hash;

  const [type, setType] = useState(getHashValue(hash));
  const [currentType, setCurrentType] = useState("1");
  const [toggleState, setToggleState] = useState("1억");
  const TITLEIMG = {
    league: TraderListTitle,
    style: TraderStyleTitle,
    alphaNote: TraderNoteTitle,
  };

  // if (isLoading) {
  //   return <p>Loading...</p>;
  // }

  // if (isError) {
  //   return <p>Error loading data</p>;
  // }

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  return (
    <HomeWrapper>
      {/* <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      /> */}
      <div className="title">
        <img src={TITLEIMG[type]} alt="traderlist-title" />
      </div>
      <SectionWrapper>
        <AlphaTradeListToggle
          setToggleState={(type) => {
            window.location.hash = type;
            setType(type);
          }}
          type={type}
        />
      </SectionWrapper>

      {type === "league" && (
        <SectionWrapper>
          <AlphaTraderRanking type={"league"} />
        </SectionWrapper>
      )}
      {type === "style" && (
        <SectionWrapper>
          <AlphaTraderRanking type={"style"} />
        </SectionWrapper>
      )}
      {type === "alphaNote" && (
        <SectionWrapper>
          <EmptyWrapper>
            <img src={EmptyLogo} alt="empty" />
            {"콘텐츠를 준비 중이에요."}
            <br />
            {"빠른 시일 내에 오픈할 예정입니다:)"}
          </EmptyWrapper>
        </SectionWrapper>
      )}
    </HomeWrapper>
  );
};

export default AlphaTraderList;
