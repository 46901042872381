import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const LoginComplete = () => {
  const { access_token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('로그인 처리 중...');

  useEffect(() => {
    if (access_token) {
      // localStorage에 access_token 저장
      localStorage.setItem('access_token', access_token);

      // 로그로 access token 출력
      console.log('Access Token:', access_token);

      setMessage('로그인 성공!');

      // 3초 후 대시보드로 리다이렉트
      setTimeout(() => {
        navigate('/');
      });
    } else {
      // 토큰이 없는 경우 에러 처리
      console.error('Access token not found');
      setMessage('로그인 실패. 다시 시도해주세요.');
      
      // 3초 후 로그인 페이지로 리다이렉트
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [access_token, navigate]);

  return (
    <div>
      <h2>{message}</h2>
      {access_token && <p>잠시 후 홈 화면으로 이동합니다...</p>}
    </div>
  );
};

export default LoginComplete;