import useHttpClient from "hooks/useHttpClient";
import { useInfiniteQuery, useQuery } from "react-query";

function useStyleTopQuery({ cid = "" }) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-scalping_top_10", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/top_style?cid=${cid}`
      );

      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useStockChangeTop10Query({ cid = "" }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-stock_change_top_10", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/stock_change_top_10?cid=${cid}`
      );
      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useConceptTraderQuery({ cid = "" }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-concept_trader", { cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/concept_trader?cid=${cid}`
      );
      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useLeagueRankerQuery({ league = "1억", cid = "" }) {
  const httpClient = useHttpClient();
  return useInfiniteQuery(
    ["trader-league_ranker", { league, cid }],
    async ({ pageParam = 0 }) => {
      const { data: queryResult } = await httpClient.get(
        `/trader/league_ranker?last_sequence=${pageParam}&league=${league}&cid=${cid}`
      );
      return queryResult?.data;
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage?.last_sequence;
      },
    }
  );
}

export {
  useConceptTraderQuery,
  useLeagueRankerQuery,
  useStockChangeTop10Query,
  useStyleTopQuery,
};

// GET
// /api/v1/trader/scalping_top_10
// Get Scalping Top 10

// GET
// /api/v1/trader/day_top_10
// Get Day Top 10

// GET
// /api/v1/trader/swing_top_10
// Get Swing Top 10

// GET
// /api/v1/trader/stock_change_top_10
// Get Stock Change Top 10

// GET
// /api/v1/trader/concept_trader
// Get Concept Trader

// GET
// /api/v1/trader/concept_trader_basic
// Get Concept Trader Basic

// GET
// /api/v1/trader/concept_trader_graph1
// Get Concept Trader Graph1

// GET
// /api/v1/trader/league_ranker
// Get League Ranker

// GET
// /api/v1/trader/analysis_summary/performance
// Get Analysis Summary Performance

// GET
// /api/v1/trader/analysis_summary/holding_time_performance
// Get Analysis Summary Holding Time Performance

// GET
// /api/v1/trader/analysis_date/performance
// Get Analysis Date Performance

// GET
// /api/v1/trader/analysis_date/history
// Get Analysis Date History

// GET
// /api/v1/trader/analysis_date/stock_change
// Get Analysis Date Stock Change

// GET
// /api/v1/trader/analysis_stock/performance
// Get Analysis Stock Performance

// GET
// /api/v1/trader/analysis_stock/history
// Get Analysis Stock History

// GET
// /api/v1/trader/analysis_stock/each
// Get Analysis Stock Daily
