import AlphaTraderCard from "components/AlphaTraderCard";
import TradeToggle from "components/TradeToggle";
import TraderLeagueTableSection from "components/TraderLeagueTableSection";
import { useAlphaRankQuery } from "hooks/queries/useAlphaTraderListQuery";
import { useState } from "react";
import styled from "styled-components";
import { LEAGUE_LABELS, TRADE_STYLE } from "utils/consts";
import { useSelectedCompetition } from "utils/store";

const SectionWrapper = styled.div`
  margin-bottom: 100px;
`;

const Wrapper = styled.div`
  .toggle-wrapper {
    margin-top: 20px;
  }
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0 40px;
`;

const ToggleWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const AlphaTraderRanking = ({ type = "league" }) => {
  const category = (() => {
    switch (type) {
      case "league":
        return LEAGUE_LABELS;
      case "style":
        return TRADE_STYLE;
      default:
        return [];
    }
  })();

  const [league, setLeague] = useState(category[0]?.type || "");
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: rankerData, refetch } = useAlphaRankQuery({
    league,
    cid: selectedCompetition?.cid,
    type,
  });

  const handleCardClick = (id) => {
    window.location.href = `/trader/${id}`;
  };

  return (
    <Wrapper>
      <ToggleWrapper>
        <TradeToggle
          setToggleState={(type) => {
            setLeague(type);
          }}
          tabNames={
            category.map((item) => {
              return item?.name;
            }) || []
          }
          toggleState={
            category.map((item) => {
              return item?.type;
            }) || []
          }
        />
      </ToggleWrapper>

      <CardSection>
        {rankerData &&
          rankerData[league]?.slice(0, 3).map((data, index) => (
            <AlphaTraderCard
              key={index}
              type={type}
              data={data}
              index={data?.sequence || index + 1}
              width={360 - 32 - 2}
              onClick={() => {
                handleCardClick(data?.cuid || "");
              }}
              updateIsLiked={() => refetch()}
            />
          ))}
      </CardSection>
      <SectionWrapper>
        {rankerData && (
          <TraderLeagueTableSection
            type={type}
            dataList={rankerData[league]?.slice(3)}
            updateIsLiked={() => refetch()}
          />
        )}
      </SectionWrapper>
    </Wrapper>
  );
};

export default AlphaTraderRanking;
