import { Link } from "react-router-dom";
import styled from "styled-components";
import { convertToPercentage } from "utils/utils";
import TaggedUserName from "./TaggedUserName";

const Index = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #e7e9ef;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Scrap = styled.div`
  color: #ffffff;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: auto;

  opacity: 0.6px;
`;

const CellWrapper = styled(Link)`
  height: 60px;
  display: flex;
  border-radius: 6px;
  padding: 0 8px;
  text-decoration: none;

  &:hover {
    background-color: rgba(247, 250, 255, 0.05);
    color: #ffffff;
  }
`;

const Profit = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  color: #ff6665;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

// Table component
const SummaryCellTrader = ({
  index = 1,
  name = "",
  tag = "",
  profit = "",
  scrapCount = "",
  isScraped = false,
  id = "",
}) => {
  return (
    <CellWrapper to={`/trader/${id}`}>
      <ItemWrapper>
        <Index>{index}</Index>
        <Item>
          <TaggedUserName name={name} tag={tag} isDarkMode={true} />
          <Profit>{convertToPercentage(profit)}</Profit>
        </Item>
        {/* <Scrap isScraped={isScraped}>{scrapCount}</Scrap> */}
      </ItemWrapper>
    </CellWrapper>
  );
};

export default SummaryCellTrader;
