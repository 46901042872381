import ArrowDropDown from "assets/images/ico_arrow_dropdown.png";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAllInOneQuery } from "hooks/queries/useCommonQuery";
import {
  useCompetitions,
  useCurrentCompetition,
  useSelectedCompetition,
} from "utils/store";
import { formatDateToMD } from "utils/utils";
import RangeSelectModal from "./Modals/RangeSelectModal";

const SelectWrapper = styled.div`
  width: fit-content;
  padding: 9px 10px;
  border-radius: 6px;
  border: 1px solid #323233;
  border-color: ${(props) =>
    props.is_dark === "dark" ? "#323233" : "#E7E9EF"};

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  color: ${(props) => (props.is_dark === "dark" ? "#f9fafc" : "#121314")};

  .icon {
    width: 20px;
    margin-right: 6px;
    display: flex;
  }

  .arrow {
    margin-left: 8px;
  }

  span {
    color: #7d7e80;
    margin-left: 4px;
  }

  cursor: pointer;
`;

const RangeSelect = ({ isDarkMode = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { competitions, setCompetitions } = useCompetitions((state) => state);
  const { setCurrentCompetition } = useCurrentCompetition((state) => state);
  const { selectedCompetition, setSelectedCompetition } =
    useSelectedCompetition((state) => state);

  const { data: competitionData, isLoading } = useAllInOneQuery();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const selectedCompetitionSubmit = (competition) => {
    setSelectedCompetition(competition);
  };

  useEffect(() => {
    if (competitionData) {
      setCompetitions(competitionData.competitions);
      setCurrentCompetition(competitionData.current_competition);
    }
    if (competitionData && selectedCompetition === null) {
      setSelectedCompetition(competitionData.current_competition);
    }
  }, [competitionData]);

  return (
    <>
      <SelectWrapper
        is_dark={isDarkMode ? "dark" : "default"}
        onClick={() => openModal()}
      >
        {isLoading || selectedCompetition === null ? (
          "Loading..."
        ) : (
          <>
            {/* <div className="icon">
              <img width={20} src={KiumLogo} alt="Kium Logo" />
            </div> */}
            {selectedCompetition?.broker + "증권 " + selectedCompetition?.name}
            <span>
              {formatDateToMD(selectedCompetition?.start, 9) +
                " ~ " +
                formatDateToMD(selectedCompetition?.end)}
            </span>
            <div className="arrow">
              <img width={12} src={ArrowDropDown} alt="ArrowDropDown" />
            </div>
          </>
        )}
      </SelectWrapper>
      <RangeSelectModal
        isOpen={isOpen}
        onSubmit={selectedCompetitionSubmit}
        onCancel={closeModal}
        competitionList={competitions}
        selectedCompetition={selectedCompetition}
      />
    </>
  );
};

export default RangeSelect;
