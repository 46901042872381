import { ReactComponent as CheckIcon } from "assets/images/ico_check.svg";
import { ReactComponent as CloseIcon } from "assets/images/ico_close_m.svg";
import ReactModal from "react-modal";
import styled from "styled-components";
import { unescapeHtml } from "utils/utils";

const customStyles = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "#00000070",
  },
  content: {
    width: "320px",
    inset: "unset",
    margin: "50vh auto",
    padding: 0,
    transform: "translateY(-50%)",
    position: "relative",
  },
};

const ModalWrapper = styled.div`
  border-radius: 8px;
  font-family: Pretendard;
  text-align: left;
`;
const ModalHeader = styled.div`
  padding: 20px 20px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.29px;

  svg {
    cursor: pointer;
  }
`;

const CompetitionList = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  height: 272px;
  overflow: auto;

  cursor: pointer;

  .selected {
    color: #ff2b5d;
    font-weight: 500;
  }
`;
const CompetitionItem = styled.div`
  padding: 17px 20px;
  display: flex;
  justify-content: space-between;

  color: ${(props) => (props.isSelect === "selected" ? "#ff2b5d" : "#141414")};
`;

const StockSelectModal = ({
  isOpen,
  onSubmit,
  onCancel,
  stockList = [],
  selectedStock,
}) => {
  const handleClickSubmit = (stock) => {
    onSubmit(stock);
    onCancel();
  };
  const handleClickCancel = () => {
    onCancel();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={handleClickCancel}
    >
      <ModalWrapper>
        <ModalHeader>
          <div>종목 선택</div>
          <CloseIcon onClick={handleClickCancel} />
        </ModalHeader>
        <CompetitionList>
          {Object.entries(stockList).map(([key, value]) => (
            <CompetitionItem
              isSelect={key === selectedStock?.code ? "selected" : ""}
              key={key}
              onClick={() => handleClickSubmit({ code: key, name: value })}
            >
              {unescapeHtml(value)}
              {key === selectedStock?.code && <CheckIcon />}
            </CompetitionItem>
          ))}
        </CompetitionList>
      </ModalWrapper>
    </ReactModal>
  );
};

export default StockSelectModal;
