import TraderCard from "components/TraderCard";
import TraderLeagueTableSection from "components/TraderLeagueTableSection";
import TraderLeagueToggle from "components/TraderLeagueToggle";
import { useLeagueRankerQuery } from "hooks/queries/useTraderQuery";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { useSelectedCompetition } from "utils/store";
import { convertToPercentage, convertToPnlRatio } from "utils/utils";

const SectionWrapper = styled.div`
  margin-bottom: 70px;
`;

const CardSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ToggleWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const TraderLeague = () => {
  const [league, setLeague] = useState("1억");

  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const {
    fetchNextPage,
    isFetchingNextPage,
    data: rankerData,
  } = useLeagueRankerQuery({ league, cid: selectedCompetition?.cid });

  const handleCardClick = (id) => {
    window.location.href = `/trader/${id}`;
  };

  useEffect(() => {}, [league]);

  return (
    <>
      <ToggleWrapper>
        <TraderLeagueToggle
          onClick={(type) => {
            setLeague(type);
          }}
        />
      </ToggleWrapper>
      <SectionWrapper>
        <CardSection>
          {rankerData &&
            rankerData?.pages[0]?.traders?.slice(0, 3).map((data, index) => (
              <TraderCard
                key={index}
                infoTitle={[
                  "트레이딩 스타일",
                  "매매 승률",
                  "매매 건수",
                  "손익비",
                  "종목수",
                ]}
                infoData={[
                  TRADE_STYLE_TYPE[data?.style],
                  convertToPercentage(data?.win_rate),
                  data?.n_tr + "회",
                  convertToPnlRatio(data?.pnl_ratio),
                  data?.n_stock + "종목",
                ]}
                data={data}
                index={data?.sequence || index + 1}
                width={360 - 32 - 2}
                onClick={() => {
                  handleCardClick(data?.cuid || "");
                }}
              />
            ))}
        </CardSection>
      </SectionWrapper>
      <SectionWrapper>
        {rankerData && (
          <TraderLeagueTableSection
            dataList={rankerData.pages}
            onClickMore={() => {
              fetchNextPage();
            }}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default TraderLeague;
