import { useState } from "react";
import styled from "styled-components";
import { LEAGUE_LABELS } from "utils/consts";

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  height: 32px;
  padding: 4px;

  gap: 0px;
  border-radius: 6px;

  margin-top: 12px;
`;

const ToggleItem = styled.div`
  flex: 1;
  padding: 4px 10px;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: ${(props) => (props.active === "true" ? "700" : "400")};
  line-height: 16px;
  letter-spacing: 0em;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #e7e9ef;

  background-color: ${(props) => props.active === "true" && "#FF3D6C"};
  color: ${(props) => (props.active === "true" ? "#FFFFFF" : "#646566")};
  cursor: pointer;

  box-shadow: ${(props) =>
    props.active === "true" ? " 0px 0px 2px 0px #0000001A" : "0"};
`;

const TraderLeagueToggle = ({ onClick = () => {} }) => {
  const [activeIndex, setActiveIndex] = useState(LEAGUE_LABELS[0].type);

  return (
    <ToggleWrapper>
      {LEAGUE_LABELS.map((item, index) => (
        <ToggleItem
          key={index}
          active={(item.type === activeIndex).toString()}
          onClick={() => {
            setActiveIndex(item.type);
            onClick(item.type);
          }}
        >
          {item.name}
        </ToggleItem>
      ))}
    </ToggleWrapper>
  );
};

export default TraderLeagueToggle;
