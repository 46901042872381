import { useEffect, useState } from "react";

import styled from "styled-components";

import { ReactComponent as ShareIcon } from "assets/images/ico_share.svg";
import AlphaTag from "components/AlphaTag";
import ShareModal from "components/Modals/ShareModal";
import { useAllInOneQuery } from "hooks/queries/useCommonQuery";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { formatDateToMD } from "utils/utils";

const UserSection = styled.div`
  padding-top: 43px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: fit-content;
`;
const UserName = styled.div`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  text-align: left;
  color: #fff;
  margin-bottom: 18px;
`;
const UserInfo = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  text-align: left;

  color: #c8c9cc;
  span {
    font-weight: 600;
    text-align: left;
    color: #fff;
    margin-left: 4px;
  }
`;
const UserDataInfo = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;
  margin-top: 6px;

  .league {
    margin-left: 8px;
    color: #afb0b2;
  }
  .date {
    margin-left: 4px;
    color: #7d7e80;
  }
  .in-league {
    margin-left: 6px;
    font-weight: 400;
    color: #99ff75;
  }
  .tag {
    margin-left: 0;

    padding: 3px 6px;
  }

  .underline {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #646566;
  }
`;
const ActionSection = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  .round-button {
    border: 1px solid #323233;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Section = styled.div`
  background-color: #121314;
  color: #fff;
  position: relative;
  height: 220px;
`;

const TabWrapper = styled.div`
  display: flex;
  background-color: #323233;
  width: fit-content;
  border-radius: 6px 6px 0px 0px;

  position: absolute;
  bottom: 0;
`;

const TabItem = styled.div`
  width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;

  line-height: 16.71px;

  border-radius: 6px 6px 0px 0px;

  font-weight: ${(props) => (props?.active ? "700" : "400")};
  color: ${(props) => (props?.active ? "#000" : "#969799")};
  background-color: ${(props) => (props?.active ? "#ffffff" : "#323233")};

  &:hover {
    background-color: #f7faff;
  }
`;

const TabComponent = ({ setTab }) => {
  const [activeTab, setActiveTab] = useState("요약");

  return (
    <TabWrapper>
      {["요약", "날짜분석", "종목분석"].map((tab, index) => (
        <TabItem
          key={index}
          active={tab === activeTab}
          onClick={() => {
            setActiveTab(tab);

            const titleTab =
              tab === "요약"
                ? "summary"
                : tab === "날짜분석"
                ? "date"
                : "stock";
            setTab(titleTab);
          }}
        >
          {tab}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

const TitleSection = ({ data, setTab }) => {
  const { data: competitionData, isLoading } = useAllInOneQuery();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    console.log("close", isOpen);
    setIsOpen(false);
  };

  useEffect(() => {
    console.log("is", isOpen);
  }, [isOpen]);

  if (isLoading) return null;

  const { competitions, current_competition } = competitionData;
  const leagueData = competitions.find((item) => item.cid === data.cid);

  return (
    <Section>
      <UserSection>
        <UserName>{data.nick}</UserName>
        <UserInfo>
          {data.league + "리그"}
          <span>{data.rank + "위"}</span>
          {" | "}
          {"투자스타일"}
          <span>{TRADE_STYLE_TYPE[data.style]}</span>
        </UserInfo>
        <UserDataInfo>
          <AlphaTag className="tag" is_dark={"dark"}>
            {"기준데이터"}
          </AlphaTag>{" "}
          <span className="underline">
            <span className="league">
              {leagueData?.broker +
                "증권 " +
                leagueData?.section +
                " " +
                leagueData?.name}
            </span>
            <span className="date">
              {formatDateToMD(leagueData?.start, 9) +
                " " +
                formatDateToMD(leagueData?.end)}
            </span>
          </span>
          {current_competition.cid === data.cid && (
            <span className="in-league">대회중</span>
          )}
        </UserDataInfo>
      </UserSection>

      <ActionSection>
        {/* <div>스크랩</div>
        <div> | </div> */}
        <div className="round-button">
          <ShareIcon onClick={() => openModal()} />
          <ShareModal
            isOpen={isOpen}
            onCancel={closeModal}
            url={window.location.href}
          />
        </div>
      </ActionSection>
      <TabComponent setTab={setTab} />
      {/* <ViewInfoSection>
        <ViewInfo>
          {123}
          <span>view</span>
        </ViewInfo>
        <ViewInfo>
          {42}
          <span>save</span>
        </ViewInfo>
        <ViewInfo>
          {112}
          <span>share</span>
        </ViewInfo>
      </ViewInfoSection> */}
    </Section>
  );
};

export default TitleSection;
