import { useState } from "react";
import styled from "styled-components";

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  height: 32px;
  padding: 4px;

  gap: 9px;
  border-radius: 6px;
  background: #ffffff;

  margin-top: 12px;
`;

const ToggleItem = styled.div`
  flex: 1;

  border-radius: 4px;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: ${(props) => (props.active === "true" ? "600" : "500")};
  line-height: 16.71px;
  letter-spacing: 0em;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => (props.active === "true" ? "#323233" : "#969799")};
  cursor: pointer;
`;

const LineItem = styled.div`
  width: 1px;
  height: 9px;

  align-self: center;
  background: #dcdee3;
`;
const TradeTextToggle = ({ onClick = {} }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const TYPE = ["ret", "pnl"];

  return (
    <ToggleWrapper>
      <ToggleItem
        key={0}
        active={(0 === activeIndex).toString()}
        onClick={() => {
          setActiveIndex(0);
          onClick(TYPE[0]);
        }}
      >
        수익률순
      </ToggleItem>
      <LineItem />

      <ToggleItem
        key={1}
        active={(1 === activeIndex).toString()}
        onClick={() => {
          setActiveIndex(1);
          onClick(TYPE[1]);
        }}
      >
        수익금순
      </ToggleItem>
    </ToggleWrapper>
  );
};

export default TradeTextToggle;
