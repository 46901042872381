import styled from "styled-components";
import { convertToPercentage, convertToWon } from "utils/utils";
import { SvgStkImg } from "./StockIcon";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 556px;
  height: 79px;
  border-radius: 8px;
  outline: 1px solid ${(props) => (props.selected ? "#22262B" : "#dcdee3")};
  background-color: ${(props) => (props.selected ? "#22262B" : "#ffffff")};
  color: ${(props) => (props.selected ? "#ffffff" : "#141414")};

  padding: 18px 34px 18px 24px;

  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;

  .card-index {
    width: 26px;
    font-family: Montserrat;
    font-size: 15px;
    font-style: italic;
    font-weight: 700;
    line-height: 18.29px;
    text-align: center;
  }
  .stock-wrapper {
    width: 100%;
    margin-left: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .price-wrapper {
    text-align: right;
  }

  .stock-info {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }

  .card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.48px;
    white-space: nowrap;
  }

  .card-description {
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;

    svg {
      margin-right: 1px;
    }
  }

  .selected {
    opacity: 0.6;
  }

  span {
    font-weight: 600;
  }

  .right {
    text-align: right;
    justify-content: right;
  }
  .red {
    color: ${(props) => (props.selected ? "#FF5857" : "#FF2727")};
  }
  .blue {
    color: ${(props) => (props.selected ? "#5E87FF" : "#4C67FF")};
  }

  &:hover {
    ${(props) =>
      props.selected
        ? "outline: 1px solid #22262B;"
        : "outline: 1px solid #000000;"};
    box-shadow: 0px 4px 24px 0px #443f870f;
  }
`;

const ArrowUpDown = ({ color = "black", direction = "up" }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {direction === "up" ? (
        <path
          d="M6.8 3.56667L9.3 6.9C9.79443 7.55924 9.32404 8.5 8.5 8.5L3.5 8.5C2.67595 8.5 2.20557 7.55924 2.7 6.9L5.2 3.56667C5.6 3.03333 6.4 3.03333 6.8 3.56667Z"
          fill={color}
        />
      ) : (
        <path
          d="M6.8 8.43333L4.3 5.1C3.80557 4.44076 4.27596 3.5 5.1 3.5L10.1 3.5C10.924 3.5 11.3944 4.44076 10.9 5.1L8.4 8.43333C8 8.96667 7.2 8.96667 6.8 8.43333Z"
          fill={color}
        />
      )}
    </svg>
  );
};

const AlphaHotStockCard = ({
  idx,
  name,
  n_ranker,
  price,
  daily_change,
  daily_change_ratio,
  code,
  selected,
  turnover,
  onClick = () => {},
}) => {
  const priceDetail =
    turnover < 0
      ? "신규상장"
      : `${convertToWon(daily_change)}(${
          daily_change >= 0 ? "+" : ""
        }${convertToPercentage(daily_change_ratio)})`;

  return (
    <Wrapper onClick={() => onClick(idx)} selected={selected}>
      <div className="card-index">{idx + 1}</div>
      <div className="stock-wrapper">
        <SvgStkImg code={code} name={name} size={30} />
        <div className="stock-info">
          <div className="card-title">{name}</div>
          <div className={`card-description`}>
            <span>{n_ranker}명</span>
            <div className={selected ? "selected" : ""}>이 거래했어요.</div>
          </div>
        </div>
      </div>

      <div className="price-wrapper">
        <div className={`card-title ${daily_change >= 0 ? "red" : "blue"}`}>
          {convertToWon(price)}
        </div>
        <div className="card-description right">
          {/* -999 : 신규상장, -998 거래재개 */}
          {turnover === -999 ? (
            "신규상장"
          ) : (
            <>
              <ArrowUpDown
                color={selected ? "white" : "black"}
                direction={daily_change >= 0 ? "up" : "down"}
              />
              {priceDetail}
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default AlphaHotStockCard;
