import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  addCommaToInt,
  convertToPercentage,
  convertToPlusWon,
  convertToPnlRatio,
  convertToTwoDecimalPlaces,
} from "utils/utils";
import AlphaTag from "./AlphaTag";
import IndexNumber from "./IndexNumber";
import TaggedUserName from "./TaggedUserName";

const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
`;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  border-radius: 8px;
`;

const StyledHeader = styled.th`
  height: 36px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;

  padding: 0 12px;
  color: #969799;
  background-color: #f7f8fa;

  &:nth-child(2) {
    text-align: left;
  }
`;

const StyledCell = styled.td`
  height: 55px;
  padding: 0 12px;
  color: ${(props) => props?.color || "#121314"};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: 0em;
  text-align: right;
  border-bottom: 1px solid #e7e9ef;

  &:nth-child(2) {
    text-align: left;
  }
`;

const TRADER_COLUMS = [
  " ",
  "닉네임",
  "스타일",
  "거래승률",
  "손익비",
  "종목",
  "거래",
  "수익률",
  "수익금",
  "",
];

const ButtonWrapper = styled.div``;
const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  width: 200px;
  height: 44px;

  padding: 0px 10px 0px 10px;

  border-radius: 6px;
  border: 1px solid #e7e9ef;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;

  &:hover {
    cursor: pointer;
  }
`;

const TraderLeagueTableSection = ({
  dataList = [],
  onClickMore = () => {},
}) => {
  return (
    <TagWrapper>
      <StyledTable>
        <thead>
          <tr>
            {TRADER_COLUMS.map((column, index) => (
              <StyledHeader key={index}>{column}</StyledHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataList.map((data, index) => {
            if (index === 0) {
              data = data.traders.slice(3);
            } else {
              data = data.traders;
            }

            return data?.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={() => {
                  window.location.href = `/trader/${row?.cuid || ""}`;
                }}
              >
                <StyledCell>
                  <IndexNumber text={row.sequence} />
                </StyledCell>
                <StyledCell>
                  <TaggedUserName name={row.nick} />
                </StyledCell>
                <StyledCell>
                  <AlphaTag type={row.style}>
                    {TRADE_STYLE_TYPE[row.style]}
                  </AlphaTag>
                </StyledCell>
                <StyledCell>{convertToPercentage(row.win_rate)}</StyledCell>
                <StyledCell>{convertToPnlRatio(row.pnl_ratio)}</StyledCell>
                <StyledCell>{addCommaToInt(row.n_stock) + "종목"}</StyledCell>
                <StyledCell>{row.n_tr + "건"}</StyledCell>
                <StyledCell color={row.ret >= 0 ? "#FF2727" : "#4C67FF"}>
                  {convertToPercentage(row.ret)}
                </StyledCell>
                <StyledCell>{convertToPlusWon(row.pnl)}</StyledCell>
                <StyledCell>{""}</StyledCell>
              </StyledTableRow>
            ));
          })}
        </tbody>
      </StyledTable>
      <ButtonWrapper>
        <Button onClick={onClickMore}>더보기</Button>
      </ButtonWrapper>
    </TagWrapper>
  );
};

export default TraderLeagueTableSection;
