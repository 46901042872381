import useHttpClient from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useAnalysisSummaryPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/my/analysis_summary/performance?cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisDatePerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-proformance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/my/analysis_date/performance?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisStockPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/my/analysis_stock/performance?cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useAnalysisStockPerformanceQuery,
  useAnalysisSummaryPerformanceQuery,
  useAnalysisDatePerformanceQuery,
};
