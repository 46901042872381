import Footer from "components/Footer/Footer";
import TopNavigation from "components/Navigation/TopNavigation";
import Home from "pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/fonts/pretendard-subset.css";

import TraderDetail from "pages/TraderDetail/TraderDetail";
import TraderList from "pages/TraderList/TraderList";
import { QueryClient, QueryClientProvider } from "react-query";
import styled from "styled-components";

import HotStock from "pages/HotStock/HotStock";
import LoginComplete from "pages/Login/LoginCallbackPage";
import LoginPage from "pages/Login/LoginPage";

import AlphaHome from "pages/Home/AlphaHome";
import TestPage from "pages/TestPage";
import TestDetail from "pages/TestPageDetail";
import StockDetail from "pages/TraderDetail/StockDetail";
import { createGlobalStyle } from "styled-components";

import LinkPage from "pages/LinkPage";
import LinkAdminPage from "pages/LinkAdminPage";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Pretendard;
  }
`;

const queryClient = new QueryClient();
const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff;

  min-width: 1240px;
`;
const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1128px;
`;
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <TopNavigation />
        <main>
          <MainWrapper>
            {/* <ContainerWrapper> */}
            <Routes>
              <Route path="/" element={<AlphaHome />}></Route>
              <Route path="/trader" element={<TraderList />}></Route>
              <Route path="/trader/:cuid" element={<TraderDetail />}></Route>
              {/* <Route path="/product/*" element={<Home />}></Route> */}
              <Route path="/hotstock/*" element={<HotStock />}></Route>
              <Route path="/note" element={<StockDetail />}></Route>
              <Route path="/competition" element={<Home />}></Route>

              <Route path="/login" element={<LoginPage />}></Route>
              <Route path="/login/complete/:access_token" element={<LoginComplete />}></Route>

              <Route path="/exp/test" element={<TestPage />}></Route>
              <Route path="/exp/detail/:cuid" element={<TestDetail />}></Route>

              <Route path="/link/:link" element={<LinkPage />}></Route>
              <Route path="/link" element={<LinkAdminPage />}></Route>

              <Route path="*" element={<AlphaHome />}></Route>
            </Routes>
            {/* </ContainerWrapper> */}
          </MainWrapper>
        </main>
        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
