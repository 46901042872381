import ko from "date-fns/locale/ko";
import { forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { ReactComponent as CalendarIcon } from "assets/images/ico_calendar.svg";

const StyledDatePickerWrapper = styled.div`
  .react-datepicker__navigation {
    top: 24px;
  }

  .react-datepicker__navigation--next {
    right: 24px;
    display: none;
  }
  .react-datepicker__navigation--previous {
    left: 24px;
    display: none;
  }

  .react-datepicker-wrapper {
    padding: 0 16px 0 22px;
  }

  .react-datepicker__input-container input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    outline: none;
  }

  .react-datepicker__input-container input:focus {
    border-color: #007bff;
  }

  .react-datepicker-popper {
    z-index: 1000;
    border-radius: 8px;
  }

  .react-datepicker {
    border-radius: 8px;
    border: 1px solid #e7e9ef;
    background-color: #ffffff;
    padding: 24px;
  }

  .react-datepicker__header {
    background-color: #ffffff;
    border: none;
    padding: 0;
  }

  .react-datepicker__current-month {
    margin-bottom: 22px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    color: #323233;
  }

  .react-datepicker__day-names {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .react-datepicker__day-name {
    font-family: Pretendard;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-align: center;
    align-content: center;
    margin: 0;
    width: 30px;
    height: 20px;
    color: #afb0b2;
  }

  .react-datepicker__week {
    display: flex;
    gap: 8px;
    margin: 0;
  }

  .react-datepicker__day {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #4b4c4d;

    margin: 0;
    place-content: center;

    width: 30px;
    height: 30px;

    &:hover {
      border-radius: 50%;
    }
  }

  .react-datepicker__month {
    width: 258px;
    margin: 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
    margin-top: 22px;
  }

  .react-datepicker__day--disabled {
    color: #c8c9cc;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #ff3d6c;
    color: #fff;
    border-radius: 50%;
  }

  .example-custom-input {
    width: 215px;
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 32.25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-left: 6px;
      padding: 6px;
      &:hover {
        path {
        stroke: none;
      }
    }
  }
`;

registerLocale("ko", ko);

const StyledDatePicker = ({ currentDate, onDateSelect, includeDates }) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const DAY = 60 * 60 * 24 * 1000;

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  const handleDateChange = (date) => {
    setSelectedDate(new Date(date));
    onDateSelect(new Date(date));
  };

  const formatDay = (date) => {
    switch (date) {
      case "일요일":
        return "SUN";
      case "월요일":
        return "MON";
      case "화요일":
        return "TUE";
      case "수요일":
        return "WED";
      case "목요일":
        return "THU";
      case "금요일":
        return "FRI";
      case "토요일":
        return "SAT";
      default:
        return "";
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
      <CalendarIcon />
    </div>
  ));

  return (
    <StyledDatePickerWrapper>
      <DatePicker
        showPopperArrow={false}
        selected={selectedDate}
        onChange={handleDateChange}
        placeholderText=""
        locale={ko}
        dateFormat={"yyyy년 M월 d일"}
        formatWeekDay={formatDay}
        includeDates={includeDates}
        customInput={<ExampleCustomInput />}
      />
    </StyledDatePickerWrapper>
  );
};

export default StyledDatePicker;
