import { create } from "zustand";

const useCompetitions = create((set) => ({
  competitions: [],
  setCompetitions: (competitions) => set({ competitions }),
}));

const useCurrentCompetition = create((set) => ({
  currentCompetition: null,
  setCurrentCompetition: (currentCompetition) => set({ currentCompetition }),
}));

const useSelectedCompetition = create((set) => ({
  selectedCompetition: null,
  setSelectedCompetition: (selectedCompetition) => set({ selectedCompetition }),
}));

const useNotice = create((set) => ({
  isNoticeShow: false,
  setNoticeShow: (isNoticeShow) => set({ isNoticeShow }),
}));

export { useCompetitions, useCurrentCompetition, useSelectedCompetition, useNotice };
