import { useEffect, useState } from "react";

import styled from "styled-components";

import { Snackbar } from "@mui/material";
import { ReactComponent as ShareIcon } from "assets/images/ico_share.svg";
import AlphaTag from "components/AlphaTag";
import ShareModal from "components/Modals/ShareModal";
import { TRADE_STYLE_TYPE } from "utils/consts";

const UserSection = styled.div`
  padding-top: 43px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: fit-content;
`;
const UserName = styled.div`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  text-align: left;
  color: #fff;
  margin-bottom: 18px;
`;
const UserInfo = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.48px;
  text-align: left;

  color: #c8c9cc;
  span {
    font-weight: 600;
    text-align: left;
    color: #fff;
    margin-left: 4px;
  }
`;
const UserDataInfo = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;
  margin-top: 6px;

  .league {
    margin-left: 8px;
    color: #939393;
  }
  .date {
    margin-left: 4px;
    color: #7d7e80;
  }
  .in-league {
    margin-left: 6px;
    font-weight: 400;
    color: #99ff75;
  }
  .tag {
    margin-left: 0;

    padding: 3px 6px;
  }

  .underline {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #646566;
  }
`;
const ActionSection = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;

  .round-button {
    border: 1px solid #323233;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Section = styled.div`
  background-color: #121314;
  color: #fff;
  position: relative;
  height: 220px;
`;

const TabWrapper = styled.div`
  display: flex;
  background-color: #323233;
  width: fit-content;
  border-radius: 6px 6px 0px 0px;

  position: absolute;
  bottom: 0;
`;

const TabItem = styled.div`
  width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  font-family: Pretendard;
  font-size: 14px;

  line-height: 16.71px;

  border-radius: 6px 6px 0px 0px;

  font-weight: ${(props) => (props?.active === "true" ? "700" : "400")};
  color: ${(props) => (props?.active === "true" ? "#000" : "#969799")};
  background-color: ${(props) =>
    props?.active === "true" ? "#ffffff" : "#323233"};

  &:hover {
    background-color: #f7faff;
  }
`;

const TabComponent = ({ setTab, selectedTab = "홈" }) => {
  const [activeTab, setActiveTab] = useState(
    selectedTab === "summary"
      ? "홈"
      : selectedTab === "date"
      ? "상세분석"
      : "알파노트"
  );

  useEffect(() => {
    setActiveTab(
      selectedTab === "summary"
        ? "홈"
        : selectedTab === "date"
        ? "상세분석"
        : "알파노트"
    );
  }, [selectedTab]);

  return (
    <TabWrapper>
      {["홈", "상세분석", "알파노트"].map((tab, index) => (
        <TabItem
          key={index}
          active={(tab === activeTab).toString()}
          onClick={() => {
            setActiveTab(tab);

            const titleTab =
              tab === "홈" ? "summary" : tab === "상세분석" ? "date" : "stock";
            setTab(titleTab);
          }}
        >
          {tab}
        </TabItem>
      ))}
    </TabWrapper>
  );
};

const InfoWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 0;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: right;
  color: #7d7e80;
  gap: 20px;

  span {
    color: #ebedf5;
    margin-right: 4px;
  }
`;
const InfoComponent = ({ value }) => {
  return (
    <InfoWrapper>
      <div>
        <span>{value?.view}</span>view
      </div>
      <div>
        <span>{value?.like}</span>like
      </div>
      <div>
        <span>{value?.bookmark}</span>save
      </div>
      <div>
        <span>{value?.share}</span>share
      </div>
    </InfoWrapper>
  );
};

const TitleSection = ({ data, setTab, selectedTab }) => {
  // const { data: competitionData, isLoading } = useAllInOneQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    // console.log("close", isOpen);
    setIsOpen(false);
  };

  useEffect(() => {
    // console.log("is", isOpen);
  }, [isOpen]);

  // if (isLoading) return null;

  const { competition: leagueData, cnt } = data || {};

  return (
    <Section>
      <UserSection>
        <UserName>{data.trader.nick}</UserName>
        <UserInfo>
          {data.trader.league + "리그"}
          <span>{data.trader.rank + "위"}</span>
          {" | "}
          {"투자스타일"}
          <span>{TRADE_STYLE_TYPE[data.style]}</span>
        </UserInfo>
        <UserDataInfo>
          <AlphaTag className="tag" is_dark={"dark"}>
            {"기준데이터"}
          </AlphaTag>
          <span className="underline">
            <span className="league">
              {leagueData?.broker +
                "증권 " +
                leagueData?.section +
                " " +
                leagueData?.name}
            </span>
            {/* <span className="date">
              {formatDateToMD(leagueData?.start, 9) +
                " " +
                formatDateToMD(leagueData?.end, 9)}
            </span> */}
          </span>

          {leagueData?.on_going && <span className="in-league">대회중</span>}
        </UserDataInfo>
      </UserSection>

      <ActionSection>
        {/* <div>스크랩</div>
        <div> | </div> */}
        <div className="round-button" onClick={() => openModal()}>
          <ShareIcon />
        </div>
        <ShareModal
          isOpen={isOpen}
          onCancel={closeModal}
          url={window.location.href}
          setSnackOpen={setSnackOpen}
        />
      </ActionSection>
      <TabComponent setTab={setTab} selectedTab={selectedTab} />
      <InfoComponent value={cnt} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={1000}
        onClose={handleSnackClose}
        message="클립보드에 복사되었습니다"
      />
    </Section>
  );
};

export default TitleSection;
