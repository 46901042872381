import { useTopStyleTraderQuery } from "hooks/queries/useHomeQuery";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { TRADE_STYLE } from "utils/consts";
import { useSelectedCompetition } from "utils/store";
import { convertToPercentage, convertToPlusWon, convertToNearWon } from "utils/utils";
import IndexNumber from "./IndexNumber";
import TaggedUserName from "./TaggedUserName";

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e7e9ef;
  border-radius: 8px;

  table-layout: fixed;
  padding: 0;
  border-spacing: 0;
`;

const StyledTableRow = styled.tr`
  &:hover {
    background-color: #f7f8fa;
  }
`;

const StyleCellWidth = css`
  .item {
    margin-left: 12px;
  }

  &:nth-child(1) {
    width: 90px;
  }
  &:nth-child(2) {
    width: 300px;
  }
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: 180px;
  }
  &:nth-child(6) {
    text-align: right;

    .item {
      margin-left: 0;
      margin-right: 12px;
    }
  }
`;

const StyledHeader = styled.th`
  height: 36px;
  text-align: left;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  color: #969799;

  border-bottom: 1px solid #e7e9ef;

  ${StyleCellWidth}
`;

const StyledCell = styled.td`
  height: 72px;
  padding: 0;
  color: #121314;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  ${StyleCellWidth}

  .itemLink {
    margin-left: 0;
    padding: 8px 12px;
    width: fit-content;
    text-decoration: none;
  }
`;

const CellLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    span {
      font-weight: 600;
    }
  }

  &:focus {
    outline: none;
    text-decoration: underline;
    color: #121314;
  }
`;

const PnlCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Pretendard;

  .pnl {
    font-size: 15px;
    font-weight: 600;
    line-height: 17.9px;
    color: #ff2727;
  }

  .ret {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    color: #323233;
  }
`;
const PnlCell = ({ pnl = 0, ret = 0 }) => {
  return (
    <PnlCellWrapper className="item">
      <div className="ret">{convertToPercentage(ret)}</div>
      {/* <div className="pnl">{convertToPlusWon(pnl)}</div> */}
      <div className="pnl">{convertToNearWon(pnl)}</div>
    </PnlCellWrapper>
  );
};

const TRADER_COLUMS = [
  " ",
  "닉네임",
  "스켈핑 승률",
  "평균 거래",
  "평균 수익률",
  "총 수익",
];

const TraderSection = ({ type = TRADE_STYLE[0].type }) => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: topStyleTraderData } = useTopStyleTraderQuery({
    cid: selectedCompetition?.cid,
  });

  return (
    <TagWrapper>
      <StyledTable>
        <thead>
          <tr>
            {TRADER_COLUMS.map((column, index) => (
              <StyledHeader key={index}>
                <div className="item">{column}</div>
              </StyledHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {topStyleTraderData?.traders[type].map((row, index) => (
            <StyledTableRow key={index}>
              <StyledCell>
                <IndexNumber text={index + 1} />
              </StyledCell>
              <StyledCell>
                <CellLink to={`/trader/${row?.cuid || ""}`}>
                  <div className="item itemLink">
                    <TaggedUserName name={row.nick} tag={row.league} />
                  </div>
                </CellLink>
              </StyledCell>
              <StyledCell>
                <div className="item">{row.win_rate.toFixed(2)}%</div>
              </StyledCell>
              <StyledCell>
                <div className="item">{row.avg_n_trx}건</div>
              </StyledCell>
              <StyledCell>
                <div className="item">{row.avg_win_rate.toFixed(2)}%</div>
              </StyledCell>
              <StyledCell>
                <PnlCell
                  pnl={row.pnl}
                  ret={row.ret}
                />
              </StyledCell>
              {/* <StyledCell /> */}
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </TagWrapper>
  );
};

export default TraderSection;
