import styled from "styled-components";
import { convertToPercentage, convertToWon, convertToNearWon, formatSec } from "utils/utils";
import IndexNumber from "./IndexNumber";
import TaggedUserName from "./TaggedUserName";

const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e7e9ef;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  border-radius: 8px;
`;

const StyledHeader = styled.th`
  height: 36px;

  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: right;

  padding: 0 12px;
  color: #969799;
  background-color: #f2f4f7;

  &:nth-child(2) {
    text-align: left;
  }

  &:first-child {
    border-radius: 8px 0 0 0;
  }
  &:last-child {
    border-radius: 0 8px 0 0;
  }
`;

const StyledCell = styled.td`
  height: 55px;
  padding: 0 12px;
  color: ${(props) => props?.color || "#121314"};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  letter-spacing: 0em;
  text-align: right;
  border-bottom: 1px solid #e7e9ef;

  &:nth-child(2) {
    text-align: left;
  }
`;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const TRADER_COLUMS = [
  " ",
  "닉네임",
  "수익률",
  "투자손익",
  "거래대금",
  "거래",
  "체결",
  "평균보유시간",
  "",
];

const TraderStockLeagueTableSection = ({ dataList }) => {
  return (
    <TagWrapper>
      <StyledTable>
        <thead>
          <tr>
            {TRADER_COLUMS.map((column, index) => (
              <StyledHeader key={index}>{column}</StyledHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataList &&
            dataList.map((row, index) => (
              <StyledTableRow
                key={index}
                onClick={() => {
                  window.open(
                    `/note?cuid=${row.note_id.cuid}&code=${row.note_id.code}&date=${row.note_id.date}`,
                    "newwindow",
                    "width=1300, height=1200"
                  );
                }}
              >
                <StyledCell>
                  <IndexNumber text={index + 1} />
                </StyledCell>
                <StyledCell>
                  <TaggedUserName name={row.nick} />
                </StyledCell>
                <StyledCell color={row.ret >= 0 ? "#FF2727" : "#4C67FF"}>
                  {convertToPercentage(row.ret)}
                </StyledCell>
                <StyledCell>{convertToNearWon(row.pnl)}</StyledCell>
                <StyledCell>{convertToNearWon(row.amt)}</StyledCell>
                <StyledCell>{row.n_tr + "건"}</StyledCell>
                <StyledCell>{row.n_trx + "건"}</StyledCell>
                <StyledCell>{formatSec(row.avg_hold_time)}</StyledCell>
                <StyledCell>{""}</StyledCell>
              </StyledTableRow>
            ))}
        </tbody>
      </StyledTable>
    </TagWrapper>
  );
};

export default TraderStockLeagueTableSection;
