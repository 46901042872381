import { SvgStkImg } from "components/StockIcon";
import { useState } from "react";
import styled from "styled-components";
import { convertToPercentage, convertToPlusWon } from "utils/utils";

const StockItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Pretendard;
  color: #000000;
  height: 31px;
  align-items: center;

  .si-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .si-name {
    margin-left: 8px;
  }

  .si-info {
    display: flex;
    flex-direction: column;
  }

  .si-pnl {
    color: #323233;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: right;
  }

  .si-ret {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: right;
    color: #ff2727;
  }

  .p {
    color: #ff2727;
  }
  .n {
    color: #4c67ff;
  }
`;

const StockItem = ({ code, name, pnl, ret }) => {
  return (
    <StockItemWrapper>
      <div className="si-icon">
        <SvgStkImg code={code} name={name} size={28} />
        <div className="si-name">{name}</div>
      </div>
      <div className="si-info">
        <div className={`si-ret ${ret >= 0 ? "p" : "n"}`}>
          {convertToPercentage(ret)}
        </div>
        <div className="si-pnl">{convertToPlusWon(pnl)}</div>
      </div>
    </StockItemWrapper>
  );
};

const CardWrapper = styled.div`
  width: 320px;
  font-family: Pretendard;

  .sl-card-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .sl-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.63px;
      color: #141414;
    }

    .sl-date {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.32px;
      color: #969799;
    }
  }

  .sl-value {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.41px;
    text-align: left;
    display: flex;
    gap: 4px;
  }

  .sl-tag {
    padding: 4px 6px;
    margin-left: 4px;
    border-radius: 100px;
    border: 1px solid #c8c9cc;
    color: #969799;

    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;

    span {
      margin-left: 2px;
      color: #646566;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.32px;
    }
  }

  .sl-value-section {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .sl-tab-wrapper {
    width: 100%;
    display: flex;
    height: 25px;
    font-family: Pretendard;
    margin-top: 20px;

    .sl-tab {
      width: 50%;
      color: #afb0b2;
      cursor: pointer;

      font-size: 14px;
      font-weight: 500;
      line-height: 16.71px;
      text-align: center;
    }

    .selected {
      border-bottom: 1px solid #141414;
      color: #141414;
    }
  }

  .sl-tab-table {
    min-height: ${(props) => props.minHeight}px;
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .sl-button {
    cursor: pointer;
    border: 1px solid #c8c9cc;
    border-radius: 6px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    align-content: center;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
  }
`;

export const StockListCard = ({
  data,
  slinenum = 6,
  minHeight = 256,
  last_update = "",
}) => {
  const [isSelectd, setIsSelected] = useState("total");
  const { complete = [], holding = [], win_rate, n_stock } = data || {};

  return (
    <CardWrapper minHeight={minHeight}>
      <div className="sl-card-title-section">
        <div className="sl-title">종목</div>
        <div className="sl-date">{last_update} 기준</div>
      </div>
      <div className="sl-value-section">
        <div className="sl-value">
          <span>{n_stock}종목</span>
        </div>
        <div className="sl-tag">
          종목승률 <span>{convertToPercentage(win_rate)}</span>
        </div>
      </div>

      <div className="sl-tab-wrapper">
        <div
          className={`sl-tab ${isSelectd === "total" ? "selected" : ""}`}
          onClick={() => {
            setIsSelected("total");
          }}
        >
          전체 종목
        </div>
        <div
          className={`sl-tab ${isSelectd === "hold" ? "selected" : ""}`}
          onClick={() => {
            setIsSelected("hold");
          }}
        >
          보유 종목
        </div>
      </div>
      <div className="sl-tab-table">
        {isSelectd === "total" &&
          complete
            .slice(0, slinenum)
            .map((item, index) => (
              <StockItem
                key={index}
                name={item.name}
                code={item.code}
                pnl={item.pnl}
                ret={item.ret}
              />
            ))}
        {isSelectd === "hold" &&
          holding
            .slice(0, slinenum)
            .map((item, index) => (
              <StockItem
                key={index}
                name={item.name}
                code={item.code}
                pnl={item.pnl}
                ret={item.ret}
              />
            ))}
      </div>
      {isSelectd === "total" && (
        <div
          className="sl-button"
          onClick={() => (window.location.href = "#stock")}
        >
          전체 보기
        </div>
      )}
      {isSelectd === "hold" && (
        <div
          className="sl-button"
          onClick={() => (window.location.href = "#stock?hold=true")}
        >
          자세히 보기
        </div>
      )}
    </CardWrapper>
  );
};
