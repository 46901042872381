import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import { useInfiniteQuery, useQuery } from "react-query";

function useAlphaTraderDetailQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/trader?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeSummaryQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_summary", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_summary?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteRecentQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "recent";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteRetQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "ret";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteLossQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "loss";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeDailyGanttQuery({ cuid, date }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_daily_gantt ", { cuid, date }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_daily_gantt?cuid=${cuid}&date=${date}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid || !!date || date !== "undefined",
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeDailyGanttDateListQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_daily_gantt_datelist", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_daily_gantt_datelist?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoSummaryQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_summary ", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_summary?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoStyleQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_style", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_style?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoChartQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_chart", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_chart?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailStockInfoQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_basic", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_basic?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailStockNoteQuery({
  cuid,
  code,
  sort_priority,
  sort_asc,
  position,
  styler_filter,
}) {
  const httpClient = useHttpClient(api_v_url);

  // cuid: cuid,
  // code: selectedStock,
  // sort_priority: priority,
  // sort_asc: sortAsc,
  // position: position,

  // return useQuery({
  //   queryKey: [
  //     "trader-detail_note",
  //     { cuid, code, sort_priority, sort_asc, position },
  //   ],
  //   queryFn: async () => {
  //     const { data: queryResult } = await httpClient.get(
  //       `/v1b/report/detail_note?cuid=${cuid}&code=${code}${
  //         sort_priority ? `&sort_priority=${sort_priority}` : ""
  //       }${sort_asc ? `&sort_asc=${sort_asc}` : ""}${
  //         position ? `&position=${position}` : ""
  //       }`
  //     );

  //     return queryResult?.data;
  //   },
  //   enabled: !!cuid,
  //   staleTime: 60 * 60 * 1000,
  // });

  return useInfiniteQuery({
    queryKey: [
      "trader-detail_note",
      { cuid, code, sort_priority, sort_asc, position, styler_filter },
    ],
    queryFn: async ({ pageParam }) => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_note?cuid=${cuid}&code=${code}${
          sort_priority ? `&sort_priority=${sort_priority}` : ""
        }${sort_asc ? `&sort_asc=${sort_asc}` : ""}${
          pageParam ? `&position=${pageParam}` : ""
        }${styler_filter ? `&style_filter=${styler_filter}` : ""}`
      );

      return queryResult?.data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.finished ? undefined : lastPage.position + 1;
    },
  });
}

function useAlphaDetailStockListQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_stock", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_stock?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTraderDateStockQuery(userId) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-stock_change", userId],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change?year=123&competition=123&cuid=${userId}`
      );

      return queryResult?.data;
    },
  });
}

function useAnalysisSummaryPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisSummaryHoldingTimePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_holding_time_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/holding_time_performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDatePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/history`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateStockChangeQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_stock_change"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisStockPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisStockHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/history`
      );
      return queryResult?.data?.stocks;
    },
  });
}

function useAnalysisStockDailyQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_daily", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/each?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useAlphaDetailHomeDailyGanttDateListQuery,
  useAlphaDetailHomeDailyGanttQuery,
  useAlphaDetailHomeNoteLossQuery,
  useAlphaDetailHomeNoteRecentQuery,
  useAlphaDetailHomeNoteRetQuery,
  useAlphaDetailHomeSummaryQuery,
  useAlphaDetailInfoChartQuery,
  useAlphaDetailInfoStyleQuery,
  useAlphaDetailInfoSummaryQuery,
  useAlphaDetailStockInfoQuery,
  useAlphaDetailStockListQuery,
  useAlphaDetailStockNoteQuery,
  useAlphaTraderDetailQuery,
  useAnalysisDateHistoryQuery,
  useAnalysisDatePerformanceQuery,
  useAnalysisDateStockChangeQuery,
  useAnalysisStockDailyQuery,
  useAnalysisStockHistoryQuery,
  useAnalysisStockPerformanceQuery,
  useAnalysisSummaryHoldingTimePerformanceQuery,
  useAnalysisSummaryPerformanceQuery,
  // useTraderDateHistoryQuery,
  // useTraderDateProformanceQuery,
  useTraderDateStockQuery,
};
