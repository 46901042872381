import bgImage from "assets/images/bg_header_home.png";
import { ReactComponent as TelegramIcon } from "assets/images/telegram_logo.svg";
import HomeSummaryCard from "components/HomeSummaryCard";
import JournalSection from "components/JournalSection";
import NoticeModal from "components/Modals/NoticeModal";
import PerformanceSection from "components/PerformanceSection";
import RangeSelect from "components/RangeSelect";
import StockSection from "components/StockSection";
import ToggleComponet from "components/ToggleComponet";
import DataInfoLine from "components/ToolTip/DataInfoLine";
import ToolTipInfoIcon from "components/ToolTip/ToolTipInfoIcon";
import TraderSection from "components/TraderSection";
import {
  useFavNoteQuery,
  useFavTraderQuery,
  useLeagueTopQuery,
} from "hooks/queries/useHomeQuery";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TELEGRAM_URL, TRADE_STYLE } from "utils/consts";
import { useNotice, useSelectedCompetition } from "utils/store";
import { formatDateTime } from "utils/utils";

const Title = styled.div`
  color: #fff;

  margin-top: 35px;
  font-family: Pretendard;

  text-align: left;
  margin-bottom: 16px;

  font-size: 28px;
  font-weight: 600;
  line-height: 33.41px;
`;

const SummaryWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

const InfoText = styled.div`
  text-align: left;
  color: #dcdee3;
  opacity: 0.4;

  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.71px;
  text-align: left;

  margin: 12px 0 6px;
`;

const SummarySection = styled.div`
  width: 100%;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HomeInfoSection = styled.div`
  background: #fff;
  padding: 16px 0 12px;
  display: flex;
  justify-content: space-between;

  align-items: end;

  .topSection {
    text-align: left;
  }

  .titleSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selection {
  }

  .title {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 4px;
  }

  .moreButton {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    color: #4b4c4d;
    text-decoration: none;
    border: 1px solid #e7e9ef;
    padding: 0 8px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 6px;

    &:hover {
      corsor: pointer;
    }
  }
`;

const SubscribeButton = styled.div`
  padding: 10px 8px;
  margin-right: 32px;
  border-radius: 6px;
  color: #fff;
  background: #ff3d6c;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  align-self: center;

  &:hover {
    cursor: pointer;
  }
`;
const SubscribeSection = styled.div`
  margin: 24px 0 34px;
  background: #f1f1f5;
  height: 90px;
  border-radius: 8px;
  border: 1px solid #0000000a;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 34px 0px #1b204933;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 8px;
  }

  .title {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #121314;
  }

  .description {
    margin-top: 4px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #7d7e80;
  }
`;

const HomeWrapper = styled.div`
  width: 100%;
`;

const SectionWrapper = styled.div`
  width: 100%;
  margin-bottom: 70px;
`;

const HomeBackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .minwidth {
    width: 1128px;
    z-index: 2;
  }

  .info-tooltip {
    overflow: visible;
    z-index: 1000;
  }
`;

const ToggleComponetWrapper = styled.div`
  margin-top: 12px;
`;

const TextWrapper = styled.div`
  display: flex;
  margin-left: 24px;
  align-self: center;

  svg {
    width: 40px;
    height: 40px;
  }
`;

const BackgroundWrapper = styled.div`
  z-index: 0;
  position: absolute;
  background: ${(props) =>
    props.background ? `url(${props.background})` : "#fff"};
  width: 100%;
  height: 640px;
  top: 0;

  min-width: 1240px;
`;

const Home = () => {
  const navigate = useNavigate();
  const [treaderType, setTraderType] = useState(TRADE_STYLE[0].type);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  const { selectedCompetition } = useSelectedCompetition((state) => state);
  const cid = selectedCompetition?.cid;
  const param = cid ? { cid } : {};

  const { data: favTraderData = [] } = useFavTraderQuery(param);
  const { data: favNoteData = [] } = useFavNoteQuery(param);
  const { data: leagueTop = [] } = useLeagueTopQuery(param);

  const { isNoticeShow } = useNotice((state) => state);

  // useEffect(() => {
  //   navigate("/hotstock");
  // }, [navigate]);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  return (
    <HomeWrapper>
      <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      />
      <HomeBackgroundWrapper>
        <BackgroundWrapper background={bgImage} />
        <div className="minwidth">
          <SummarySection>
            <TitleSection>
              <Title>트레이더 분석의 새로운 방식, 알파노트</Title>
              <RangeSelect isDarkMode={true} />
            </TitleSection>
            <InfoText>{`Last updated : ${formatDateTime(
              selectedCompetition?.last_update
            )}`}</InfoText>
            <SummaryWrapper>
              <HomeSummaryCard
                key="hot-trader"
                title="HOT 트레이더🔥"
                type="trader"
                data={favTraderData?.traders}
              />
              <HomeSummaryCard
                key="hot-report"
                title="HOT 투자기록🔥"
                type="journal"
                data={favNoteData?.notes}
              />
              <HomeSummaryCard
                key="hot-ranker"
                title="리그별 랭킹 1위"
                type="ranker"
                data={leagueTop?.traders}
              />
            </SummaryWrapper>
            <SubscribeSection>
              <TextWrapper>
                <TelegramIcon />
                <div className="wrapper">
                  <div className="title">알파노트 텔레그램을 구독해주세요</div>
                  <div className="description">
                    {`트레이더 투자 전략 분석, 주도주 공략법, 보유 종목 통계 등의 다양한 최신 정보를 보내드립니다.`}
                  </div>
                </div>
              </TextWrapper>
              <SubscribeButton
                onClick={() => window.open(TELEGRAM_URL, "_blank")}
              >
                구독하기
              </SubscribeButton>
            </SubscribeSection>
          </SummarySection>
        </div>
      </HomeBackgroundWrapper>
      <HomeBackgroundWrapper>
        <div className="minwidth info-tooltip">
          <DataInfoLine />
        </div>
      </HomeBackgroundWrapper>

      <HomeBackgroundWrapper>
        <div className="minwidth">
          <SectionWrapper>
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">Top 스타일별 트레이더</div>
                  <ToolTipInfoIcon
                    tooltipKey="home-style-tooltip"
                    title="나의 투자 스타일과 맞는 최상위권 트레이더들을 확인해보세요"
                    body={[
                      "*스캘핑: 5분 이내의 짧은 투자",
                      "*데이: 1일 이내의 투자",
                      "*스윙: 1일 이상의 투자",
                    ]}
                  />
                </div>
                <ToggleComponetWrapper>
                  <ToggleComponet
                    labels={TRADE_STYLE}
                    onClick={setTraderType}
                  />
                </ToggleComponetWrapper>
              </div>
              <Link className="moreButton" to="/trader">
                전체보기
              </Link>
            </HomeInfoSection>
            <TraderSection type={treaderType} />
          </SectionWrapper>
          <SectionWrapper>
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">Top 주도주</div>
                  <ToolTipInfoIcon
                    tooltipKey="home-stock-tooltip"
                    title="상위권 투자자들이 가장 많이 투자한 종목을 확인해보세요"
                  />
                </div>
              </div>
              <Link className="moreButton" to="/hotstock">
                전체보기
              </Link>
            </HomeInfoSection>
            <StockSection />
          </SectionWrapper>
          <SectionWrapper>
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">Top 거래일지</div>
                  <ToolTipInfoIcon
                    tooltipKey="home-note-tooltip"
                    body={[
                      "1. 대회기간동안 가장 큰 수익률을 기록한 거래를 확인해 보세요",
                      "2. 상위권 투자자들의 수익 비법을 확인해보세요",
                    ]}
                  />
                </div>
              </div>
            </HomeInfoSection>
            <JournalSection />
          </SectionWrapper>
          <SectionWrapper>
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">시장 vs 랭커 수익률</div>
                  <ToolTipInfoIcon
                    tooltipKey="home-ranker-tooltip"
                    title="시장(KOSPI, KOSDAQ)에 비해 상위권 트레이더들은 어떤 성과를 내는지 확인해보세요"
                  />
                </div>
              </div>
            </HomeInfoSection>
            <PerformanceSection />
          </SectionWrapper>
        </div>
      </HomeBackgroundWrapper>
    </HomeWrapper>
  );
};

export default Home;
