import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import ReactModal from "react-modal";
import ScrollToTop from "utils/ScrollToTop";
import LoginPage from "pages/Login/LoginPage";
import LoginComplete from "pages/Login/LoginCallbackPage";
import PrivacyPolicyPage from "pages/Experiment/Policy";
import TermsOfServicePage from "pages/Experiment/Terms";

ReactModal.setAppElement("#root");

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

const MainApp = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/login/complete/:access_token"
          element={<LoginComplete />}
        />
        <Route path="/policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="*" element={<App />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <MainApp />);
} else {
  root.render(<MainApp />);
}

reportWebVitals();
