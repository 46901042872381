const LoginPage = () => {
  const handleKakaoLogin = async () => {
    const REST_API_KEY = process.env.REACT_APP_KAKAO_CLIENT;
    const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT;
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    window.location.href = KAKAO_AUTH_URL;
  }

  return (
    <div>
      <button onClick={handleKakaoLogin}>Login with Kakao</button>
      {/* <button onClick={handleGoogleLogin}>Login with Google</button>; */}
    </div>
  ) 
};

export default LoginPage;
