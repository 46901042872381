import useHttpClient from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useNote({ note_id = "" }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["note-report", { note_id }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/note?cuid=${note_id.cuid}&code=${note_id.code}&date=${note_id.date}`
      );
      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

export { useNote };
