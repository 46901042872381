import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import {
  convertToNearWon,
  convertToPercentage,
  formatSeconds,
} from "utils/utils";
import AlphaStyleTag from "./AlphaStyleTag";

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 225px;
  height: 176px;
  cursor: pointer;

  z-index: ${(props) => (props.selected ? "100" : "")};

  padding: 16px;

  border-radius: ${(props) =>
    props.position === "left"
      ? "8px 0px 0px 8px"
      : props.position === "right"
      ? "0px 8px 8px 0px"
      : "0"};
  border-radius: ${(props) => (props.selected ? "8px" : "")};

  outline: 1px solid ${(props) => (props.selected ? "#22262B" : "#DCDEE3")};
  background-color: ${(props) => (props.selected ? "#ffffff" : "#F7F8FA")};
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .red {
    color: ${(props) => (props.selected ? "#FF5857" : "#FF2727")};
  }
  .blue {
    color: ${(props) => (props.selected ? "#5E87FF" : "#4C67FF")};
  }

  .user-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .name {
      font-size: 19px;
      font-weight: 600;
      line-height: 22.67px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .trade-wrapper {
    margin-top: 6px;
    opacity: 0.6;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: left;
    align-items: center;
    display: flex;

    svg {
      width: 13px;
    }
  }

  .info-wrapper {
  }

  .info-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    align-items: center;
  }
  .info-line:last-child {
    margin-bottom: 0;
  }
  .info-line:first-child {
    margin-bottom: 6px;
  }

  .info {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    opacity: 0.4;
  }

  .value {
    text-align: right;
    color: #4b4c4d;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
  }

  .pnl {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: right;
    color: #000000;
  }

  &:hover {
    box-shadow: 0px 4px 24px 0px #443f870f;
  }
`;

export const Dot = ({ color = "636364" }) => {
  return (
    <svg width="3" height="3" viewBox="0 0 3 3" fill="black">
      <circle cx="1.5" cy="1.5" r="1.5" fill={color} />
    </svg>
  );
};

const AlphaStockTraderCard = ({
  idx,
  nick,
  n_tr,
  avg_hold_time,
  pnl,
  ret,
  amt,
  selected,
  style,
  position,
  onClick = () => {},
}) => {
  return (
    <Wrapper
      onClick={() => {
        onClick(idx);
      }}
      selected={selected}
      position={position}
    >
      <div>
        <div className="user-wrapper">
          <div className="name">{nick}</div>
          <AlphaStyleTag type={style}>{TRADE_STYLE_TYPE[style]}</AlphaStyleTag>
        </div>
        <div className="trade-wrapper">
          <span>체결 {n_tr}건</span>
          <Dot />
          <span></span>
          <span>보유 {formatSeconds(avg_hold_time)}</span>
        </div>
      </div>
      <div className="info-wrapper">
        <div className="info-line">
          <div className="info">수익률</div>
          <div className="value pnl">{convertToPercentage(ret)}</div>
        </div>
        <div className="info-line">
          <div className="info">수익금</div>
          <div className="value">{convertToNearWon(pnl)}</div>
        </div>
        <div className="info-line">
          <div className="info">투자금</div>
          <div className="value">{convertToNearWon(amt)}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AlphaStockTraderCard;
