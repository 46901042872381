import { Bar, BarChart, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import TaggedUserName from "./TaggedUserName";

import { ReactComponent as InfoSmallIcon } from "assets/images/ico_info_s.svg";
import { TRADE_STYLE_TYPE } from "utils/consts";
import AlphaTag from "./AlphaTag";

const CardWrapper = styled.div`
  width: ${(props) => props.width - 2 || 360 - 2}px;
  min-width: ${(props) => props.width - 2 || 360 - 2}px;

  border-radius: 8px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  border: 1px solid #000000;
  opacity: 0px;
  box-shadow: 2px 4px 18px 0px #00000014;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }
`;
const ProfitSection = styled.div`
  margin-top: 24px;
  margin-bottom: 5px;

  display: flex;
  justify-content: space-between;
`;
const InfoSection = styled.div`
  margin-top: 25px;
`;
const NameSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfitValueWrapper = styled.div``;
const ProfitPercent = styled.div`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 600;
  line-height: 33.41px;
  text-align: left;

  margin-top: 5px;
`;
const ProfitInfo = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  gap: 3px;
`;

const InfoWrapper = styled.div`
  display: flex;

  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const InfoName = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;

  color: #969799;
`;

const InfoData = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  margin-left: 4px;
  line-height: 15.51px;
  opacity: 0.6;
  color: #000000;
`;

const TagSection = styled.div`
  width: fit-content;
  margin-bottom: 8px;
`;

const CustomBarShape = (props) => {
  const { fill, x, y, width, height } = props;

  const slantHeight = height / 2;

  const path = `M ${x},${y} L ${x + width + slantHeight},${y} L ${x + width},${
    y + height
  } L ${x},${y + height} Z`;

  return <path d={path} stroke="none" fill={fill} />;
};

const CustomBarShape2 = (props) => {
  const { fill, x, y, width, height } = props;

  const slantHeight = height / 2;

  const path = `M ${x + slantHeight},${y} L ${x + width},${y} L ${x + width},${
    y + height
  } L ${x},${y + height} L ${x + slantHeight},${y}`;

  return <path d={path} stroke="none" fill={fill} />;
};

const ChartWrapper = styled.div`
  margin-bottom: 33px;
`;

const ChartInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InfoValue = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.32px;
  text-align: left;
  display: flex;

  .red {
    color: #ff3a6a;
    margin-left: 6px;
  }

  .gray {
    color: #ebedf5;
    margin-left: 6px;
  }
`;

const TraderInfoCard = ({
  data,
  index,
  infoTitle = ["거래 날짜", "거래 종목", "종목 승률", "거래 횟수"],
  width = 360,
  type = "default",
  represent = "",
  represent_hint = "",
  // noYAxis = false,
  //   tag = "",
  //   isScalping = false,
  //   isDarkMode = false,
}) => {
  return (
    <CardWrapper
      width={width}
      onClick={() => {
        window.location.href = `/trader/${data?.cuid || ""}`;
      }}
    >
      <NameSection>
        <TagSection>
          <AlphaTag style={{ marginLeft: 0 }} type={data.league}>
            {data.league}
          </AlphaTag>
          <AlphaTag type={data.style}>{TRADE_STYLE_TYPE[data.style]}</AlphaTag>
        </TagSection>
        <TaggedUserName name={data.nick} isDarkMode={false} fontWeight={600} />
      </NameSection>
      <ProfitSection>
        <ProfitValueWrapper>
          <ProfitInfo>
            {represent}
            <InfoSmallIcon
              data-tooltip-id="trader-tooltip"
              data-tooltip-content={represent_hint}
            />
          </ProfitInfo>
          <ProfitPercent>{data.represent_value}</ProfitPercent>
        </ProfitValueWrapper>
      </ProfitSection>
      {type === "graph" && (
        <ChartWrapper>
          <BarChart
            width={233}
            height={12}
            stackOffset="expand"
            data={[{ name: "", win: data.win, lose: data.lose }]}
            style={{ stroke: "#fff", strokeWidth: 1 }}
            layout="vertical"
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <XAxis type="number" hide={true} />
            <YAxis type="category" dataKey="name" hide={true} />
            <Bar
              dataKey="win"
              stackId="a"
              fill="#FF3A6A"
              shape={<CustomBarShape height={4} />}
            />
            <Bar
              dataKey="lose"
              stackId="a"
              fill="#EBEDF5"
              shape={<CustomBarShape2 height={4} />}
            />
          </BarChart>
          <ChartInfo>
            <InfoValue>
              <div>승리</div>
              <div className="red">{data.win}</div>
            </InfoValue>
            <InfoValue>
              <div>패배</div>
              <div className="gray">{data.lose}</div>
            </InfoValue>
          </ChartInfo>
        </ChartWrapper>
      )}
      <InfoSection>
        <InfoWrapper>
          <InfoName>{infoTitle[0]}</InfoName>
          <InfoData>{data.attribute_values[0]}</InfoData>
        </InfoWrapper>
        <InfoWrapper>
          <InfoName>{infoTitle[1]}</InfoName>
          <InfoData>{data.attribute_values[1]}</InfoData>
        </InfoWrapper>
      </InfoSection>
    </CardWrapper>
  );
};

export default TraderInfoCard;
