import ScrollCardSection from "components/ScrollCardSection";
import ScrollConceptSection from "components/ScrollConceptSection";
import ScrollGanttSection from "components/ScrollGanttSection";
import TradeTextToggle from "components/TradeTextToggle";
import {
  useConceptTraderQuery,
  useStockChangeTop10Query,
  useStyleTopQuery,
} from "hooks/queries/useTraderQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SUBSCRIBE_URL, TRADE_STYLE } from "utils/consts";
import { useSelectedCompetition } from "utils/store";
import DocumentImage from "../../assets/images/img_empty_document@2x.png";
const HomeInfoSection = styled.div`
  background: #fff;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  max-width: 1128px;
  width: 100%;

  .topSection {
    text-align: left;
  }

  .titleSection {
    display: flex;
  }

  .selection {
  }

  .title {
    font-family: Pretendard;
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  }

  .description {
    margin-top: 4px;
    font-family: Pretendard;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.9px;
    text-align: left;
    color: #969799;
  }

  .moreButton {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff3d6c;
    margin-top: auto;
  }
`;

const SubscribeSection = styled.div`
  max-width: 1128px;
  width: 100%;
  margin: 16px 0;
  background: #3c6cf6;
  height: 120px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 34px 0px #1b204933;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
  }

  .title {
    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: left;
    color: #ffffff;
  }

  .description {
    margin-top: 9px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    opacity: 0.5;
    color: #ffffff;
  }

  img {
    height: 100%;
    position: absolute;
    right: 0;
  }
`;

const SectionWrapper = styled.div`
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .topSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const TopTrader = () => {
  const navigate = useNavigate();

  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: styleData } = useStyleTopQuery({
    cid: selectedCompetition?.cid,
  });

  const { data: reportData } = useStockChangeTop10Query({
    cid: selectedCompetition?.cid,
  });

  const { data: conceptData } = useConceptTraderQuery({
    cid: selectedCompetition?.cid,
  });

  const [scalpingSortType, setScalpingSortType] = useState("ret");
  const [daySortType, setDaySortType] = useState("ret");
  const [swingSortType, setSwingSortType] = useState("ret");

  return (
    <>
      <div style={{ background: "#FFFFFF", marginTop: 16 }}>
        <SectionWrapper>
          <div className="topSectionWrapper">
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">스캘핑 트레이더️ Top10</div>
                </div>
                <div className="description">
                  5분 이내로 거래를 끝내는 트레이더
                </div>
              </div>
              <TradeTextToggle onClick={setScalpingSortType} />
            </HomeInfoSection>
          </div>
          <ScrollCardSection
            data={
              scalpingSortType === "ret"
                ? styleData?.scalping.ret
                : styleData?.scalping.pnl || []
            }
            type={TRADE_STYLE[0].type}
            width={232}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="topSectionWrapper">
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">데이 트레이더 Top10</div>
                </div>
                <div className="description">
                  하루안에 거래를 끝내는 트레이더
                </div>
              </div>
              <TradeTextToggle onClick={setDaySortType} />
            </HomeInfoSection>
          </div>
          <ScrollCardSection
            data={
              daySortType === "ret"
                ? styleData?.day.ret
                : styleData?.day.pnl || []
            }
            type={TRADE_STYLE[1].type}
            width={232}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="topSectionWrapper">
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">스윙 트레이더 Top10</div>
                </div>
                <div className="description">
                  하루 이상의 거래를 하는 트레이더
                </div>
              </div>
              <TradeTextToggle onClick={setSwingSortType} />
            </HomeInfoSection>
          </div>
          <ScrollCardSection
            data={
              swingSortType === "ret"
                ? styleData?.swing.ret
                : styleData?.swing.pnl || []
            }
            type={TRADE_STYLE[2].type}
          />
        </SectionWrapper>
        <SectionWrapper>
          <div className="topSectionWrapper">
            <SubscribeSection
              onClick={() => {
                window.open(SUBSCRIBE_URL, "_blank");
              }}
            >
              <div className="wrapper">
                <div className="title">
                  알파노트를 구독하고 트레이더 분석을 무료로 받아보세요.
                </div>
                <div className="description">{`알파노트 구독하러 가기 ->`}</div>
                <img src={DocumentImage} alt="document illustrator" />
              </div>
            </SubscribeSection>
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <div className="topSectionWrapper">
            {/* <ChartSectionWrapper> */}
            <HomeInfoSection>
              <div className="topSection">
                <div className="titleSection">
                  <div className="title">일 수익률 Top 10</div>
                </div>
                <div className="description">
                  하루 동안 수익을 가장 많이 낸 트레이더의 투자기록
                </div>
              </div>
            </HomeInfoSection>
          </div>
          <ScrollGanttSection data={reportData} />
        </SectionWrapper>
        {conceptData &&
          conceptData?.concepts.map((item, index) => {
            const {
              type,
              title,
              intro,
              represent,
              represent_hint,
              attributes,
            } = item.concept;
            return (
              <SectionWrapper>
                <HomeInfoSection>
                  <div className="topSection">
                    <div className="titleSection">
                      <div className="title">{title}</div>
                    </div>
                    <div className="description">{intro}</div>
                  </div>
                </HomeInfoSection>
                <ScrollConceptSection
                  data={item.traders}
                  represent={represent}
                  represent_hint={represent_hint}
                  type={type}
                  title={attributes}
                  width={232}
                />
              </SectionWrapper>
            );
          })}
      </div>
    </>
  );
};

export default TopTrader;
