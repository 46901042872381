import RangeSelect from "components/RangeSelect";
import { useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import LeagueSection from "./LeagueSection";

import bgImage from "assets/images/bg_header_hotstock.png";

import { ReactComponent as ArrowUp } from "assets/images/ico_arrow_dropdown_up.svg";
import { ReactComponent as ArrowLeft } from "assets/images/ico_arrow_left_s.svg";
import { ReactComponent as ArrowRight } from "assets/images/ico_arrow_right_s.svg";
import { useNotice, useSelectedCompetition } from "utils/store";
import {
  convertToPercentage,
  convertToWon,
  formatDateToYMD,
  unescapeHtml,
} from "utils/utils";

import NoticeModal from "components/Modals/NoticeModal";
import StyledDatePicker from "components/StyledDatePicker";
import ko from "date-fns/locale/ko";
import { useHotQuery } from "hooks/queries/useHotStockQuery";
import { isMobile } from "react-device-detect";

import { SvgStkImg } from "components/StockIcon";

registerLocale("ko", ko);

const ArrowDown = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: "rotate(180deg)" }}
  >
    <path
      d="M6.8 3.56667L9.3 6.9C9.79443 7.55924 9.32404 8.5 8.5 8.5L3.5 8.5C2.67595 8.5 2.20557 7.55924 2.7 6.9L5.2 3.56667C5.6 3.03333 6.4 3.03333 6.8 3.56667Z"
      fill={NEGATIVE_COLOR}
    />
  </svg>
);

const Title = styled.div`
  color: #000000;

  font-family: Pretendard;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: left;
  margin-bottom: 8px;
`;
const Description = styled.div`
  color: #000;
  opacity: 0.6;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;
  margin-bottom: 16px;
`;
const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 16px 58px;
  justify-content: center;
  align-items: center;

  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const HomeWrapper = styled.div`
  width: 100%;
  background: #f7f8fa;
`;

const StockListWrapepr = styled.div``;

const DatePickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 26px;

  .customDatePicker {
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
  }

  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid colors.$GRAY6;
    border-radius: 4px;

    box-sizing: border-box;
    width: 100%;
    height: 46px;

    text-align: center;
    padding-right: 14px;

    font-family: Pretendard;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.25px;
    text-align: center;

    box-shadow: 2px 16px 19px 0px #00000017;
  }
`;

const DatePickerSection = ({ currentDate, onDateSelect, includeDates }) => {
  const [selectedDate, setSelectedDate] = useState(currentDate);

  useEffect(() => {
    setSelectedDate(currentDate);
  }, [currentDate]);

  const handlePrevDate = () => {
    // handleDateChange(new Date(selectedDate.getTime() - DAY));
    // currentDate to prev date in includeDates except already index 0
    const index = includeDates.findIndex(
      (date) => date === formatDateToYMD(selectedDate)
    );

    if (index > 0) {
      handleDateChange(new Date(includeDates[index - 1]));
    }
  };

  const handleNextDate = () => {
    const index = includeDates.findIndex(
      (date) => date === formatDateToYMD(selectedDate)
    );
    if (index < includeDates.length - 1) {
      handleDateChange(new Date(includeDates[index + 1]));
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  return (
    <DatePickerWrapper>
      <ArrowLeft onClick={handlePrevDate} />
      <StyledDatePicker
        currentDate={currentDate}
        onDateSelect={onDateSelect}
        includeDates={includeDates}
      />
      <ArrowRight onClick={handleNextDate} />
    </DatePickerWrapper>
  );
};

const StockDataCardWrapper = styled.div`
  transform: ${(props) =>
    props.is_selected === "true" ? "scale(1)" : "scale(0.9)"};
  transition: transform 0.3s;

  padding: 16px;
  border: 1px solid #000000;
  border-radius: 8px;
  min-width: 326px;
  max-width: 360px;

  display: inline-block;

  background: #ffffff;
  color: #000000;
  font-family: Pretendard;
  margin-bottom: 48px;
  box-shadow: 4px 12px 28px 0px #43445133;

  .stockTitle {
    display: flex;
    align-items: end;
    height: 20px;

    .index {
      font-family: Montserrat;
      font-size: 16px;
      font-style: italic;
      font-weight: 700;
      line-height: 19.5px;
      text-align: center;
      color: #323233;
    }

    .name {
      margin-left: 10px;
      font-family: Pretendard;
      font-size: 18px;
      font-weight: 600;
      line-height: 17.9px;
      text-align: left;
    }

    .code {
      margin-left: 4px;
      background: #f2f2f2;

      font-size: 12px;
      font-weight: 600;
      line-height: 14.32px;
      text-align: center;
      color: #8a8a8a;

      padding: 2px 4px;
      border-radius: 4px;
    }
  }

  .priceInfo {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;

    .price {
      font-size: 26px;
      font-weight: 600;
      line-height: 31.03px;
    }

    .changeWrapper {
      display: flex;
      font-size: 15px;
      align-items: center;

      .daily_change {
        margin-left: 6px;
        font-weight: 600;
      }
    }
  }

  .stockInfo {
    display: flex;
    flex-direction: column;

    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: left;

    padding: 12px;
    background: #fafafa;
    border-radius: 6px;

    margin-top: 12px;
    gap: 6px;

    &:first-child {
      margin-top: 18px;
    }

    .infoWrapper {
      display: flex;
      justify-content: space-between;

      font-family: Pretendard;
      font-size: 13px;
      font-weight: 400;
      line-height: 15.51px;
      text-align: left;

      .infoText {
        color: #969799;
      }
      .infoValue {
        color: #323233;
        font-weight: 500;
      }
    }
  }

  .traderInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 12px;
    background: #fafafa;
    border-radius: 6px;

    margin-top: 12px;

    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: right;

    .infoText {
      color: #969799;
    }

    .traderInfoWrapper {
      .traderValueText {
        font-weight: 500;
      }

      .traderInfoText {
        color: #969799;
      }
    }
  }
`;

const ColorLable = styled.div`
  color: ${(props) => props.color};
`;

const POSITIVE_COLOR = "#ff2727";
const NEGATIVE_COLOR = "#4C67FF";

const StockDataCard = ({ data, onClick, isSelected = false, index = 0 }) => {
  const cardRef = useRef(null);
  return (
    <StockDataCardWrapper
      ref={cardRef}
      onClick={onClick}
      is_selected={isSelected.toString()}
    >
      <div className="stockTitle">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="index">{index + 1}</div>
          &nbsp; &nbsp;
          <SvgStkImg code={data.code} name={data.name} size={30} />
          <div className="name"> {unescapeHtml(data.name)} </div>
          <div className="code">{data.code}</div>
        </div>
      </div>
      <div className="priceInfo">
        <ColorLable
          color={data?.daily_change >= 0 ? POSITIVE_COLOR : NEGATIVE_COLOR}
          className="price"
        >
          {convertToWon(data?.close)}
        </ColorLable>
        <div className="changeWrapper">
          <div className="text">전일대비</div>
          <ColorLable
            color={data?.daily_change >= 0 ? POSITIVE_COLOR : NEGATIVE_COLOR}
            className="daily_change"
          >
            {convertToWon(data.daily_change)}
            {`(${convertToPercentage(data.daily_change_ratio)})`}
          </ColorLable>
          {data?.daily_change >= 0 ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>

      <div className="traderInfo">
        <div className="infoText">참여랭커</div>
        <div className="traderInfoWrapper">
          <div className="traderValueText">{data.n_ranker}명</div>
          <div className="traderInfoText">
            {"스캘핑" +
              data.n_scalping +
              ", 데이" +
              data.n_day +
              ", 스윙" +
              data.n_swing}
          </div>
        </div>
      </div>

      <div className="traderInfo">
        <div className="infoText">랭커 승률</div>
        <div className="traderInfoWrapper">
          <div className="traderValueText">
            {convertToPercentage(
              (data.n_win / (data.n_lose + data.n_win)) * 100
            )}
          </div>
          <div className="traderInfoText">
            {"익절 트레이딩 " + data.n_win + ", 손절 트레이딩 " + data.n_lose}
          </div>
        </div>
      </div>
      {/* 
      <div className="traderInfo">
        <div className="infoText">평균 수익</div>
        <div className="traderInfoWrapper">
          <div className="traderValueText">
            {convertToPercentage(data.avg_ret)}
          </div>
          <div className="traderInfoText">{convertToWon(data.avg_pnl)}</div>
        </div>
      </div>

      <div className="stockInfo">
        <div className="infoWrapper">
          <div className="infoText">고가</div>
          <div className="infoValue">{convertToWon(data.high)}</div>
        </div>
        <div className="infoWrapper">
          <div className="infoText">저가</div>
          <div className="infoValue">{convertToWon(data.low)}</div>
        </div>
        <div className="infoWrapper">
          <div className="infoText">회전율</div>
          <div className="infoValue">{convertToPercentage(data.turnover)}</div>
        </div>
        <div className="infoWrapper">
          <div className="infoText">거래대금</div>
          <div className="infoValue">{convertToWon(data.amt)}</div>
        </div>
      </div> */}
    </StockDataCardWrapper>
  );
};

const StockScrollSectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SpcaeWrapper = styled.div`
  min-width: calc(100vw / 2 - 180px);
`;

const ScrollOverlayWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const OverlayLeft = styled.div`
  width: 173px;
  height: 377px;
  gap: 0px;
  top: 21px;
  left: 0;
  angle: -180 deg;
  position: absolute;
  background: linear-gradient(
    90deg,
    #f7f8fa 15.14%,
    rgba(247, 248, 250, 0) 100%
  );
`;
const OverlayRight = styled.div`
  width: 173px;
  height: 377px;
  position: absolute;
  top: 21px;
  right: 0;
  opacity: 0px;
  background: linear-gradient(
    270deg,
    #f7f8fa 15.14%,
    rgba(247, 248, 250, 0) 100%
  );
`;

const StockScrollSection = ({ date, onCardSelect, stockData }) => {
  // const { data: stockData } = useDailyHotQuery();

  const [selectedCard, setSelectedCard] = useState(0);
  const scrollRef = useRef(null);

  const handleCardClick = (index) => {
    onCardSelect(index);
    setSelectedCard(index);

    scrollRef.current.scrollTo({
      left: index * 360,
      behavior: "smooth",
    });
  };

  return (
    <ScrollOverlayWrapper>
      <StockScrollSectionWrapper ref={scrollRef}>
        <SpcaeWrapper />
        {stockData &&
          stockData?.map((stock, index) => {
            return (
              <StockDataCard
                key={index}
                data={stock}
                index={index}
                onClick={() => handleCardClick(index)}
                isSelected={selectedCard === index}
              />
            );
          })}
        <SpcaeWrapper />
      </StockScrollSectionWrapper>
      {stockData && (
        <>
          <OverlayLeft />
          <OverlayRight />
        </>
      )}
    </ScrollOverlayWrapper>
  );
};

const StockListSection = ({
  onCardSelect,
  onDateSelect,
  currentDate,
  stockData,
  acceptableDates,
}) => {
  return (
    <StockListWrapepr>
      <DatePickerSection
        onDateSelect={onDateSelect}
        currentDate={currentDate}
        includeDates={acceptableDates}
      />
      <StockScrollSection
        onCardSelect={onCardSelect}
        date={currentDate}
        stockData={stockData}
      />
    </StockListWrapepr>
  );
};

const TextWrapper = styled.div`
  max-width: 1128px;
  width: 100%;
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
`;
const HotStock = () => {
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const yesterday = new Date().setDate(new Date().getDate() - 1);

  const [acceptableDates, setAcceptableDates] = useState(
    selectedCompetition?.dates
  );
  const [currentDate, setCurrentDate] = useState(
    acceptableDates?.length > 0
      ? acceptableDates[acceptableDates.length - 1]
      : yesterday
  );
  const [selectedCard, setSelectedCard] = useState(0);

  const { data: hotData } = useHotQuery({
    cid: selectedCompetition?.cid,
    date: formatDateToYMD(currentDate),
  });

  useEffect(() => {
    const aAates = selectedCompetition?.dates;

    if (aAates) {
      setAcceptableDates(aAates);
      setCurrentDate(aAates[aAates.length - 1]);
    }
  }, [selectedCompetition]);

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  return (
    <HomeWrapper>
      <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      />
      <TitleSection>
        <TextWrapper>
          <Title>시장을 주도하는 "주도주"</Title>
          <Description>
            각 날짜별 랭커들의 수익률이 높은 종목과 대표 트레이더를 확인 할 수
            있어요.
          </Description>
          <RangeSelect />
        </TextWrapper>
      </TitleSection>
      <StockListSection
        onCardSelect={(index) => setSelectedCard(index)}
        currentDate={currentDate}
        onDateSelect={(date) => setCurrentDate(date)}
        stockData={hotData?.stocks}
        acceptableDates={acceptableDates}
      />
      <SectionWrapper>
        {hotData && (
          <LeagueSection leagueData={hotData?.stocks[selectedCard]} />
        )}
      </SectionWrapper>
    </HomeWrapper>
  );
};

export default HotStock;
