import useHttpClient from "hooks/useHttpClient";
import { useQuery } from "react-query";

// GET
// /api/v1/stock/daily_hot
// Get Daily Hot Stock

// GET
// /api/v1/stock/ohlc_with_marker
// Get Ohlc With Marker

// GET
// /api/v1/stock/represent_ranker
// Get Represent Ranker

function useHotStockQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["stock-daily_hot"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get("/stock/daily_hot");
      return queryResult?.data;
    },
  });
}

function useOhlcWithMarkerQuery({ selectedCard = 0, league, cid = "" }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["stock-ohlc_with_marker", { selectedCard, league, cid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        "/stock/ohlc_with_marker"
      );
      return queryResult?.data;
    },
    enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useRepresentRankerQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["stock-represent_ranker"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        "/stock/represent_ranker"
      );
      return queryResult?.data?.traders;
    },
  });
}

function useHotQuery({ cid = "", date = "" }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["stock-hot", { cid, date }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/stock/hot?cid=${cid}&date=${date} `
      );
      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useHotQuery,
  useHotStockQuery,
  useOhlcWithMarkerQuery,
  useRepresentRankerQuery,
};
