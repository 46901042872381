import styled from "styled-components";
import { unescapeHtml } from "utils/utils";
import backgroundImg from "../assets/images/bg_gradation@2x.png";
import SummaryCellJournal from "./SummaryCellJournal";
import SummaryCellRanker from "./SummaryCellRanker";
import SummaryCellTrader from "./SummaryCellTrader";

const CardWrapper = styled.div`
  width: 328px;
  height: 336px;
  padding: 16px;
  border-radius: 6px;
  background-image: url(${(props) => props.image});
  border: Mixed solid;
`;
const Title = styled.div`
  font-family: Pretendard;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 4px 8px 16px;
`;

const HomeSummaryCard = ({ title = "", type = "", data = [] }) => {
  return (
    <CardWrapper image={backgroundImg}>
      <Title>{title}</Title>
      {data.map((row, index) => {
        switch (type) {
          case "trader":
            return (
              <SummaryCellTrader
                key={index}
                index={index + 1}
                name={row.nick}
                tag={row.league}
                profit={row.ret}
                scrapCount="5k" // n_like
                isScraped={true}
                id={row.cuid}
              />
            );
          case "journal":
            return (
              <SummaryCellJournal
                key={index}
                index={index + 1}
                name={row.nick}
                tag={row.type}
                profit={row.ret}
                scrapCount="5k"
                date={row.daterange}
                subject={unescapeHtml(row.stock_name)}
                isScraped={true}
                note_id={row.note_id}
              />
            );
          case "ranker":
            return (
              <SummaryCellRanker
                key={index}
                index={index + 1}
                name={row.nick}
                tag={row.league}
                profit={row.ret}
                profitWon={row.pnl}
                description={row.n_ranker}
                scrapCount="5k"
                isScraped={true}
                id={row.cuid}
              />
            );
          default:
            return null;
        }
      })}
    </CardWrapper>
  );
};

export default HomeSummaryCard;
