import useHttpClient from "hooks/useHttpClient";
import { useQuery } from "react-query";

function useTraderDateProformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-proformance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/performance?cuid=${cuid}&cid=${cid}`
      );

      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTraderDateHistoryQuery({ cid, cuid }) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-history", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/history?cuid=${cuid}&cid=${cid}`
      );

      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTraderDateStockQuery(userId) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-stock_change", userId],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change?year=123&competition=123&cuid=${userId}`
      );

      return queryResult?.data;
    },
  });
}

function useAnalysisSummaryPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisSummaryHoldingTimePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_holding_time_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/holding_time_performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDatePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/history`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateStockChangeQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_stock_change"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisStockPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisStockHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/history`
      );
      return queryResult?.data?.stocks;
    },
  });
}

function useAnalysisStockDailyQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_daily", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/each?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

export {
  useAnalysisDateHistoryQuery,
  useAnalysisDatePerformanceQuery,
  useAnalysisDateStockChangeQuery,
  useAnalysisStockDailyQuery,
  useAnalysisStockHistoryQuery,
  useAnalysisStockPerformanceQuery,
  useAnalysisSummaryHoldingTimePerformanceQuery,
  useAnalysisSummaryPerformanceQuery,
  useTraderDateHistoryQuery,
  useTraderDateProformanceQuery,
  useTraderDateStockQuery,
};
