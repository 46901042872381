import { PerformanceCard } from "components/Card/PerformanceCard";
import { StockListCard } from "components/Card/StockListCard";
import { TradingStyleCard } from "components/Card/TradingStyleCard";
import {
  useAlphaDetailHomeNoteRecentQuery,
  useAlphaDetailHomeSummaryQuery,
} from "hooks/queries/useAlphaTraderDetailQuery";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { convertToPercentage } from "utils/utils";
import GanttChartSection from "./GanttChartSection";
import NoteCardSection from "./NoteCardSection";

const DetailWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;

  .title-section {
    margin: 40px 0 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 23.87px;
      text-align: left;
    }

    .info {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.32px;
      text-align: right;
      color: #afb0b2;
    }
  }
`;

const SummaryWrapper = styled.div`
  width: 100%;
  height: 530px;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #f5f1f0;
  border: 1px solid #ebe8e8;
  border-radius: 16px;

  .stock {
    padding: 0 10px 0 40px;
    border-left: 1px dashed #d1cccb;
  }
`;

const SummaryCard = ({ data }) => {
  return (
    <SummaryWrapper>
      <PerformanceCard data={data?.summary} />
      <TradingStyleCard
        data={data?.style}
        value={convertToPercentage(
          (Math.max(
            data?.style?.n_tr.day,
            data?.style?.n_tr.swing,
            data?.style?.n_tr.scalping
          ) /
            data?.style?.total_tr) *
            100
        )}
        styleValue={TRADE_STYLE_TYPE[data?.style?.represent_style]}
        title="트레이딩 스타일"
      />
      <div className="stock">
        <StockListCard
          data={data?.stock}
          last_update={data?.last_update}
          minHeight={291}
        />
      </div>
    </SummaryWrapper>
  );
};

export const getNoteKey = (payload) => {
  if (payload.daterange) {
    return `${payload.code}_${payload.cuid}_${payload.daterange.slice(0, 10)}`;
  } else if (payload.date) {
    return `${payload.code}_${payload.cuid}_${payload.date.slice(0, 10)}`;
  } else return "";
};

const AlphaDetailHome = ({ cuid, setLastUpdate }) => {
  const { data: summaryData, isLoading: summaryLoading } =
    useAlphaDetailHomeSummaryQuery({
      cuid: cuid,
    });

  const { data: noteData } = useAlphaDetailHomeNoteRecentQuery({
    cuid: cuid,
  });

  const [likeStates, setLikeStates] = useState({});

  useEffect(() => {
    if (summaryData && !summaryLoading) {
      setLastUpdate(summaryData.last_update);
    }
  }, [summaryData, summaryLoading, setLastUpdate]);

  return (
    <DetailWrapper>
      <div className="title-section">
        <div className="title">요약</div>
        <div className="info">
          *알파노트에서 가공한 데이터는 실제 데이터와 차이가 있을 수 있습니다.
        </div>
      </div>
      {summaryData && <SummaryCard data={summaryData} />}
      {noteData && (
        <>
          <NoteCardSection
            title="최근 알파노트"
            data={noteData.recent}
            likeStates={likeStates}
            setLikeStates={setLikeStates}
          />
          <GanttChartSection title="Best 일자노트" cuid={cuid} />
          <NoteCardSection
            title="Best 알파노트"
            data={noteData.ret}
            likeStates={likeStates}
            setLikeStates={setLikeStates}
          />
          <NoteCardSection
            title="Loss 알파노트"
            data={noteData.loss}
            likeStates={likeStates}
            setLikeStates={setLikeStates}
          />
        </>
      )}
    </DetailWrapper>
  );
};

export default AlphaDetailHome;
